<div class="page-wrapper bg_alice">
    <app-menu></app-menu>

    <section class="page-title" style="background-image: url(assets/images/nosotros/our.png);">
        <div class="auto-container">
            <!-- <h1>Preguntas frecuentes</h1> -->
            <h1>Conceptos</h1>
            <div class="text">¿Tienes algunda duda? Esperamos poder resolvertela con las preguntas más frecuentes que nos sugieren nuestros clientes.</div>
        </div>
    </section>

    <section class="what-we-do pb-2">
        <div class="auto-container">
            <div class="sec-title text-center">
                <!-- <h2>Conceptos Flex</h2> -->
                <span class="divider"></span>
                <div class="text">La evolución del mercado ha contribuido a modificar la forma de los espacios de trabajo, integrando aspectos como creación, colaboración, modernidad, resiliencia, diseño, intercambio de ideas, sostenibilidad, etc. todo ello en un marco
                    innovador que ha obligado a redefinir nuevos conceptos que incorporen estos atributos en las modalidades de utilización de superficies de oficinas. Con ánimo de acercarnos a conceptos relacionados con el espacio de trabajo, a continuación
                    , detallamos una relación de definiciones y preguntas habituales que nos pueden ofrecer un mayor conocimiento de las posibles alternativas que podemos encontrar a la hora de elegir nuestro próximo lugar de trabajo:

                </div>
            </div>
        </div>
    </section>

    <section class="accordion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!--Accordion wrapper-->
                    <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingOne1">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                    <h5 class="mb-0 py-2">
                                        Oficina tradicional <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseOne1" class="collapse show" role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
                                <div class="card-body">
                                    Arrendamiento de espacio de oficinas mediante un contrato privado de arrendamiento, asumiendo la gestión del espacio, la contratación de todos los suministros y los costes de adecuación junto al mobiliario (Implantación).

                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingTwo2">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                    <h5 class="mb-0 py-2">
                                        Oficina compartida <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseTwo2" class="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
                                <div class="card-body">
                                    Compartir una oficina convencional con una o varias empresas, donde cada una dispone de su propio espacio independiente o puestos de trabajo delimitados y donde se comparten zonas comunes tales como recepción, salas de reunión u office. Es una opción
                                    utilizada para repartir los costes de la oficina entre varias compañías. Dichas compañías pueden tener relación o ser completamente independientes.

                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree3">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3">
                                    <h5 class="mb-0 py-2">
                                        Centro de negocios <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseThree3" class="collapse" role="tabpanel" aria-labelledby="headingThree3" data-parent="#accordionEx">
                                <div class="card-body">
                                    Es un emplazamiento en el que se ofrecen espacios físicos de trabajo equipados tales como oficinas, despachos, salas de reuniones etc. El objetivo fundamental es que las compañías puedan llevar a cabo su actividad profesional de una manera sencilla sin
                                    comprometer largos períodos de arrendamiento y utilizando desde el primer día instalaciones y servicios que, individualmente, tendrían un elevado coste de adquisición y mantenimiento.

                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree4">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree4" aria-expanded="false" aria-controls="collapseThree4">
                                    <h5 class="mb-0 py-2">
                                        Coworking <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseThree4" class="collapse" role="tabpanel" aria-labelledby="headingThree4" data-parent="#accordionEx">
                                <div class="card-body">
                                    Es una práctica por la que profesionales que no comparten ni empresa ni sector de actividad, se unen para trabajar juntos en un mismo espacio, pero aportándose valor mutuamente a través de las conexiones generadas. No sólo se trata de ahorrar dinero,
                                    sino de encontrar entornos de trabajo innovadores que nos permitan ser más abiertos y productivos.

                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree5">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree5" aria-expanded="false" aria-controls="collapseThree5">
                                    <h5 class="mb-0 py-2">
                                        Espacio flexible de trabajo <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseThree5" class="collapse" role="tabpanel" aria-labelledby="headingThree5" data-parent="#accordionEx">
                                <div class="card-body">
                                    Es la evolución de la oficina tradicional cuyo objetivo es satisfacer las necesidades de las empresas en función del tipo de trabajo a desarrollar siendo completamente adaptable a su evolución en el tiempo.


                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree6">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree6" aria-expanded="false" aria-controls="collapseThree6">
                                    <h5 class="mb-0 py-2">
                                        Oficina virtual <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseThree6" class="collapse" role="tabpanel" aria-labelledby="headingThree6" data-parent="#accordionEx">
                                <div class="card-body">
                                    Es un espacio y un complejo de servicios virtuales (y reales) en el que se llevan a cabo actividades parecidas a las de una oficina tradicional. Su finalidad es minimizar el coste de estructura de la compañía y reduciendo todos los gastos iniciales de
                                    una oficina física que comparativamente pueden ser muy elevados.



                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree7">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree7" aria-expanded="false" aria-controls="collapseThree7">
                                    <h5 class="mb-0 py-2">
                                        ¿Quién puede utilizar un espacio flexible de oficinas? <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseThree7" class="collapse" role="tabpanel" aria-labelledby="headingThree5" data-parent="#accordionEx">
                                <div class="card-body">
                                    No existe un perfil predeterminado ya que, desde un freelance o pequeña empresa, hasta una gran corporación que desee acometer un proyecto durante un período de tiempo determinado en un lugar de trabajo atractivo e inspiracional sin realizar grandes desembolsos
                                    económicos de partida, puede ser usuario de un espacio de esta tipología.
                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree8">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree8" aria-expanded="false" aria-controls="collapseThree8">
                                    <h5 class="mb-0 py-2">
                                        Características de un espacio flexible <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <!-- <div id="collapseThree8" class="collapse" role="tabpanel" aria-labelledby="headingThree8" data-parent="#accordionEx">
                                <div class="card-body">
                                    Desde un puesto de trabajo en zona común a un espacio privado u organizar reuniones o eventos, es denominador común en espacios flexibles aspectos como la atención al cliente, disponibilidad horaria, equipamiento tecnológico todo ello en un entorno moderno
                                    e inspiracional

                                </div>
                            </div> -->
                            <div id="collapseThree8" class="collapse" role="tabpanel" aria-labelledby="headingThree8" data-parent="#accordionEx">
                                <div class="card-body">
                                    Desde un puesto de trabajo en zona común, a un espacio privado, u organizar reuniones o eventos. Es denominador común en espacios flexibles aspectos como la atención al cliente, disponibilidad horaria, equipamiento tecnológico todo ello en un entorno
                                    moderno e inspiracional.
                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree9">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree9" aria-expanded="false" aria-controls="collapseThree9">
                                    <!-- <h5 class="mb-0 py-2">
                                        Ventajas y desventajas de un espacio flexible <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5> -->
                                    <h5 class="mb-0 py-2">
                                        Aspectos a tener en cuenta <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseThree9" class="collapse" role="tabpanel" aria-labelledby="headingThree9" data-parent="#accordionEx">
                                <div class="card-body">
                                    <!-- <p>Ventajas:</p> -->
                                    <ul>
                                        <li>- Ahorro de costes: Permite minorar los importes de inicio de una actividad empresarial o nuevo proyecto. </li>
                                        <li>- Flexibilidad en tarifas y usos: la existencia de diferentes tarifas y planes de horarios permiten al usuario elegir la modalidad más apropiada para su negocio.
                                        </li>
                                        <li>- Colaboración: Posibilidad de establecer sinergias con otros coworkers, empresas o emprendedores, que están trabajando en el mismo espacio.
                                        </li>
                                        <li>- Networking: compartir espacio de trabajo favorece de manera natural la generación de contactos.
                                        </li>
                                        <!-- </ul> -->
                                        <!-- <p>Desventajas</p> -->
                                        <!-- <ul> -->
                                        <li>- Menor privacidad a la hora de tratar temas confidenciales.
                                        </li>
                                        <li>- El entorno compartido puede generar mayores distracciones.
                                        </li>
                                        <li>- En espacios abiertos podemos encontrar más ruido y, por tanto, mayor dificultad de concentración.
                                        </li>
                                        <li>- Los horarios en ocasiones pueden ser limitados.
                                        </li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->

                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree7">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree10" aria-expanded="false" aria-controls="collapseThree10">
                                    <h5 class="mb-0 py-2">
                                        ¿Cómo elegir el espacio más adecuado? <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseThree10" class="collapse" role="tabpanel" aria-labelledby="headingThree10" data-parent="#accordionEx">
                                <div class="card-body">
                                    Aspectos como la tipología de trabajo a desarrollar, ubicación, privacidad, área personalizada así como los servicios que ofrece el centro, son factores determinantes en la elección del futuro espacio de trabajo.


                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->
                        <!-- Accordion card -->
                        <div class="card mb-4">

                            <!-- Card header -->
                            <div class="card-header" role="tab" id="headingThree11">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree11" aria-expanded="false" aria-controls="collapseThree11">
                                    <h5 class="mb-0 py-2">
                                        Tarifas <i class="fas fa-angle-down rotate-icon"></i>
                                    </h5>
                                </a>
                            </div>

                            <!-- Card body -->
                            <div id="collapseThree11" class="collapse" role="tabpanel" aria-labelledby="headingThree11" data-parent="#accordionEx">
                                <!-- <div class="card-body">
                                    El precio del espacio de trabajo variará en función del centro elegido de acuerdo con su localización, modelo de trabajo que elegido por el usuario y los servicios que se incluyan. Normalmente la tarifa contratada incluye una relación de servicios determinados,
                                    no obstante suelen existir opcionales que pueden incorporarse o simplemente contratarse eventualmente en función de las necesidades del usuario
                                </div> -->
                                <div class="card-body">
                                    El precio del espacio de trabajo variará en función del centro elegido: de acuerdo con su localización, modelo de trabajo a desarrollar por el usuario y los servicios que se incluyan. Normalmente la tarifa contratada incluye una relación de servicios
                                    determinados, no obstante, suelen existir opcionales que pueden incorporarse o simplemente contratarse eventualmente en función de las necesidades del usuario.
                                </div>
                            </div>

                        </div>
                        <!-- Accordion card -->
                    </div>
                    <!-- Accordion wrapper -->
                </div>
            </div>
        </div>
    </section>

</div>

<app-footer></app-footer>