<div class="page-wrapper bg_alice">
    <app-menu></app-menu>

    <section class="page-title" style="background-image: url(assets/images/nosotros/our.png);">
        <div class="auto-container">
            <h1>Política de Cookies</h1>
        </div>
    </section>

    <section class="what-we-do pb-2">
        <div class="auto-container">
            <div class="sec-title">


                <h4 class="my-2">Política de Cookies</h4>
                <div class="text">
                    En esta web se utilizan cookies de terceros y propias para conseguir que tengas una mejor experiencia de navegación, puedas compartir contenido en redes sociales y para que podamos obtener estadísticas de los usuarios.
                </div>
                <div class="text">
                    Puedes evitar la descarga de cookies a través de la configuración de tu navegador, evitando que las cookies se almacenen en su dispositivo.
                </div>
                <div class="text">
                    Como propietario de este sitio web, te comunico que no utilizamos ninguna información personal procedente de cookies, tan sólo realizamos estadísticas generales de visitas que no suponen ninguna información personal.
                </div>
                <div class="text">
                    Es muy importante que leas la presente política de cookies y comprendas que, únicamente se almacenarán en tu dispositivo las cookies necesarias para la navegación. El resto de cookies se instalarán siempre y cuando lo consientas expresamente.
                </div>
                <div class="text">
                    Según los términos incluidos en el artículo 22.2 de la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico, si continúas navegando, estarás prestando tu consentimiento para el empleo de los referidos mecanismos.
                </div>

                <h4 class="my-2">Entidad Responsable </h4>
                <div class="text">
                    La entidad responsable de la recogida, procesamiento y utilización de tus datos personales, en el sentido establecido por la Ley de Protección de Datos personales, es la página www.borneoadvisors.com, propiedad de BORNEO REAL ESTATE, S.L., y dirección
                    en Edificio Cuzco II C/ Poeta Joan Maragall, 38 - 2º izquierda, 28020, Madrid.
                </div>

                <h4 class="my-2">¿Qué son las cookies?</h4>
                <div class="text">
                    Las cookies son un conjunto de datos que un servidor deposita en el navegador del usuario para recoger la información de registro estándar de Internet y la información del comportamiento de los visitantes en un sitio web. Es decir, se trata de pequeños
                    archivos de texto que quedan almacenados en el disco duro del ordenador y que sirven para identificar al usuario cuando se conecta nuevamente al sitio web. Su objetivo es registrar la visita del usuario y guardar cierta información.
                    Su uso es común y frecuente en la web ya que permite a las páginas funcionar de manera más eficiente y conseguir una mayor personalización y análisis sobre el comportamiento del usuario.
                </div>

                <h4 class="my-2">¿Qué tipo de cookies existen?</h4>
                <div class="text">
                    Las cookies utilizadas en nuestro sitio web, son de sesión y de terceros, y nos permiten almacenar y acceder a información relativa al idioma, el tipo de navegador utilizado, y otras características generales predefinidas por el usuario, así como, seguir
                    y analizar la actividad que lleva a cabo, con el objeto de introducir mejoras y prestar nuestros servicios de una manera más eficiente y personalizada.
                </div>
                <div class="text">
                    Las cookies, en función de su permanencia, pueden dividirse en cookies de sesión o permanentes. Las que expiran cuando el usuario cierra el navegador. Las que expiran en función de cuando se cumpla el objetivo para el que sirven (por ejemplo, para que
                    el usuario se mantenga identificado en los servicios de BORNEO REAL ESTATE, S.L. o bien cuando se borran manualmente.
                </div>
                <div class="text">
                    Adicionalmente, en función de su objetivo, las cookies pueden clasificarse de la siguiente forma:
                </div>
                <ul class="text">
                    <li>
                        - <strong>Cookies de rendimiento</strong>: Este tipo de Cookie recuerda sus preferencias para las herramientas que se encuentran en los servicios, por lo que no tiene que volver a configurar el servicio cada vez que usted visita.
                        A modo de ejemplo, en esta tipología se incluyen: Ajustes de volumen de reproductores de vídeo o sonido. Las velocidades de transmisión de vídeo que sean compatibles con su navegador. Los objetos guardados en el “carrito de la
                        compra” en los servicios de e-commerce tales como tiendas.
                    </li>
                    <li>
                        - <strong>Cookies de geo-localización</strong>: Estas cookies son utilizadas para averiguar en qué país se encuentra cuando se solicita un servicio. Esta cookie es totalmente anónima, y sólo se utiliza para ayudar a orientar el
                        contenido a su ubicación.
                    </li>
                    <li>
                        - <strong>Cookies de registro</strong>: Las cookies de registro se generan una vez que el usuario se ha registrado o posteriormente ha abierto su sesión, y se utilizan para identificarle en los servicios con los siguientes objetivos:
                        <ul class="text">
                            <li>
                                a. Mantener al usuario identificado de forma que, si cierra un servicio, el navegador o el ordenador y en otro momento u otro día vuelve a entrar en dicho servicio, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse.
                                Esta funcionalidad se puede suprimir si el usuario pulsa la funcionalidad [cerrar sesión], de forma que esta cookie se elimina y la próxima vez que entre en el servicio el usuario tendrá que iniciar sesión para estar identificado.
                            </li>
                            <li>
                                b. Comprobar si el usuario está autorizado para acceder a ciertos servicios, por ejemplo, para participar en un concurso.
                            </li>
                            <li>
                                c. Adicionalmente, algunos servicios pueden utilizar conectores con redes sociales tales como Facebook o Twitter. Cuando el usuario se registra en un servicio con credenciales de una red social, autoriza a la red social a guardar una Cookie persistente
                                que recuerda su identidad y le garantiza acceso a los servicios hasta que expira. El usuario puede borrar esta Cookie y revocar el acceso a los servicios mediante redes sociales actualizando sus preferencias en la red social
                                que específica.
                            </li>
                        </ul>
                    </li>

                    <li>
                        - <strong>Cookies analíticas</strong>: Cada vez que un usuario visita un servicio, una herramienta de un proveedor externo genera una cookie analítica en el ordenador del usuario. Esta cookie que sólo se genera en la visita, servirá
                        en próximas visitas a los servicios de BORNEO REAL ESTATE, S.L., para identificar de forma anónima al visitante. Los objetivos principales que se persiguen son:
                        <ul class="text">
                            <li>
                                a. Permitir la identificación anónima de los usuarios navegantes a través de la cookie (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo.
                            </li>
                            <li>
                                b. Identificar de forma anónima los contenidos más visitados y por lo tanto más atractivos para los usuarios Saber si el usuario que está accediendo es nuevo o repite visita.
                            </li>
                        </ul>
                        Importante: Salvo que el usuario decida registrarse en un servicio BORNEO REAL ESTATE, S.L., la cookie nunca irá asociada a ningún dato de carácter personal que pueda identificarle. Dichas cookies sólo serán utilizadas con propósitos estadísticos que
                        ayuden a la optimización de la experiencia de los usuarios en el sitio.
                    </li>

                    <li>
                        - <strong>Cookies de publicidad</strong>: Este tipo de cookies permiten ampliar la información de los anuncios mostrados a cada usuario anónimo en los servicios de BORNEO REAL ESTATE, S.L. Entre otros, se almacena la duración o
                        frecuencia de visualización de posiciones publicitarias, la interacción con las mismas, o los patrones de navegación y/o comportamientos del usuario ya que ayudan a conformar un perfil de interés publicitario. De este modo, permiten
                        ofrecer publicidad afín a los intereses del usuario.
                    </li>

                    <li>
                        - <strong>Cookies publicitarias de terceros</strong>: Además de la publicidad gestionada por las webs BORNEO REAL ESTATE, S.L. en sus servicios, las webs de BORNEO REAL ESTATE, S.L. ofrecen a sus anunciantes la opción de servir
                        anuncios a través de terceros (“Ad- Servers”). De este modo, estos terceros pueden almacenar cookies enviadas desde los servicios de BORNEO REAL ESTATE, S.L. procedentes de los navegadores de los usuarios, así como acceder a los
                        datos que en ellas se guardan.
                    </li>
                </ul>

                <h4 class="my-2">¿Como puedo deshabilitar las cookies en mi navegador?</h4>
                <div class="text">
                    Se pueden configurar los diferentes navegadores para avisar al usuario de la recepción de cookies y, si se desea, impedir su instalación en el equipo. Asimismo, el usuario puede revisar en su navegador qué cookies tiene instaladas y cuál es el plazo de
                    caducidad de las mismas, pudiendo eliminarlas.
                </div>
                <div class="text">
                    Para ampliar esta información consulte las instrucciones y manuales de su navegador:
                </div>
                <ul class="text">
                    <li>
                        - Para más información sobre la administración de las cookies en Google Chrome: <br>
                        <a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a>
                    </li>
                    <li>
                        - Para más información sobre la administración de las cookies en Internet Explorer: <br>
                        <a target="_blank" href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#:~:text=En%20Internet%20Explorer%2C%20seleccione%20el,si%20prefieres%20que%20te%20pregunten.">https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#:~:text=En%20Internet%20Explorer%2C%20seleccione%20el,si%20prefieres%20que%20te%20pregunten.</a>
                    </li>
                    <li>
                        - Para más información sobre la administración de las cookies en Mozilla Firefox: <br>
                        <a target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions">http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions</a>
                    </li>
                    <li>
                        - Para más información sobre la administración de las cookies en Safari: <br>
                        <a target="_blank" href="http://www.apple.com/es/privacy/use-of-cookies/">http://www.apple.com/es/privacy/use-of-cookies/</a>
                    </li>
                    <li>
                        - Para más información sobre la administración de las cookies en Opera: <br>
                        <a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a>
                    </li>
                    <li>
                        - Si desea dejar de ser seguido por Google Analytics visite: <br>
                        <a target="_blank" href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>
                    </li>
                </ul>




                <!-- <h4 class="my-2">Política de Cookies</h4>
                <div class="text">Si quieres saber más sobre el uso de cookies que realiza este sitio
                    web http://borneoflex.es/ estás en el lugar indicado.  A continuación, vamos a explicarte qué son
                    exactamente las cookies; qué tipo de cookies utilizamos y para qué; y cómo puedes ejercer tu derecho
                    para configurar tu navegador y desestimar el uso de cualquiera de ellas.
                    Eso sí, debes saber que, si decides no utilizar algunas cookies, este sitio web puede no funcionar
                    perfectamente, afectando a tu experiencia de usuario.
                </div>
                <h4 class="my-2">¿Qué es una Cookie?</h4>
                <div class="text">Una cookie es un fichero que se descarga en tu ordenador al acceder a determinadas
                    páginas web o blogs.
                    Las cookies permiten a esa página, entre otras cosas, almacenar y recuperar información sobre tus
                    hábitos de navegación o de tu equipo, y dependiendo de la información que contengan y de la forma en
                    que utilices tu equipo, pueden utilizarse para reconocerte.
                    El navegador del usuario memoriza cookies en el disco duro solamente durante la sesión actual
                    ocupando un espacio de memoria mínimo y no perjudicando al ordenador. Las cookies no contienen
                    ninguna clase de información personal específica, y la mayoría de las mismas se borran del disco
                    duro al finalizar la sesión de navegador (las denominadas cookies de sesión).
                    La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las
                    mismas, permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas.
                    Las cookies se asocian al navegador, no a la persona, por lo que no suelen almacenar información
                    sensible sobre ti como tarjetas de crédito o datos bancarios, fotografías o información personal,
                    etc. Los datos que guardan son de carácter técnico, estadísticos, preferencias personales,
                    personalización de contenidos, etc.
                </div>
                <h4 class="my-2">Aceptación de las Cookies: Normativa Vigente</h4>
                <div class="text">Al acceder a este sitio web, y de acuerdo a la normativa vigente en materia de
                    protección de datos, te informamos del uso de cookies, dándote la opción de aceptarlas expresamente
                    y de acceder a más información a través de esta Política de Cookies.
                    Debes saber que, en el caso de continuar navegando, estarás prestando tu consentimiento para el
                    empleo de estas cookies. Pero, en cualquier momento, podrás cambiar de opinión y bloquear su
                    utilización a través de tu navegador.
                    Para tu total tranquilidad, este sitio web cumple con lo estipulado en la normativa vigente en
                    relación con el uso de las cookies y tus datos personales:
                    El reglamento LSSI-CE (Ley de la sociedad de la información y del comercio electrónico)
                    El RGPD (Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016
                    relativo a la protección de las personas físicas), que unifica la regulación del tratamiento de los
                    datos personales en los países de la UE.
                    Esta Política de Cookies podría modificarse en cualquier momento para adaptarse a novedades
                    normativas o cambios en nuestras actividades, siendo vigente la que en cada momento se encuentre
                    publicada en la web.
                </div>
                <h4 class="my-2">Tipos de Cookies</h4>
                <div class="text">Para poder ofrecerte una mejor experiencia de usuario, obtener datos analíticos,
                    almacenar y recuperar información sobre tus hábitos de navegación o de tu equipo y desarrollar su
                    actividad, este sitio web https://borneflex.es utiliza cookies propias y de terceros.
                    ¿Qué tipos de cookies utiliza este sitio web?
                    <ul>
                        <li>
                            Cookies técnicas: Son aquéllas que permiten al usuario la navegación a través de una página
                            web,
                            plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella
                            existan
                            como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión,
                            acceder a
                            partes de acceso restringido, recordar los elementos que integran un pedido, realizar el
                            proceso de
                            compra de un pedido, realizar la solicitud de inscripción o participación en un evento,
                            utilizar
                            elementos de seguridad durante la navegación, almacenar contenidos para la difusión de
                            videos o
                            sonido o compartir contenidos a través de redes sociales.
                        </li>

                        <li>
                            Cookies de personalización: Son aquéllas que permiten al usuario acceder al servicio con
                            algunas
                            características de carácter general predefinidas en función de una serie de criterios en el
                            terminal
                            del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede
                            al
                            servicio, la configuración regional desde donde accede al servicio, etc.
                        </li>

                        <li>
                            Cookies de análisis: Son aquéllas que bien tratadas por nosotros o por terceros, nos
                            permiten
                            cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la
                            utilización que hacen los usuarios del servicio ofertado. Para ello se analiza tu navegación
                            en
                            nuestra página web con el fin de mejorar la oferta de productos o servicios que le
                            ofrecemos.
                        </li>

                        <li>
                            Cookies publicitarias: Son aquéllas que, bien tratadas por nosotros o por terceros, nos
                            permiten
                            gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en
                            la
                            página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso
                            que
                            realice de nuestra página web. Para ello podemos analizar tus hábitos de navegación en
                            Internet y
                            podemos mostrarte publicidad relacionada con tu perfil de navegación.
                        </li>

                        <li>
                            Cookies de publicidad comportamental: Son aquéllas que permiten la gestión, de la forma más
                            eficaz
                            posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una
                            página web,
                            aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan
                            información del comportamiento de los usuarios obtenida a través de la observación
                            continuada de sus
                            hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar
                            publicidad en
                            función del mismo.
                        </li>

                        <li>
                            Cookies de terceros: Esta web http://borneoflex.es/ puede utilizar servicios de terceros
                            que, por
                            cuenta de Google, recopilaran información con fines estadísticos, de uso del sitio por parte
                            del
                            usuario y para la prestación de otros servicios relacionados con la actividad del sitio web
                            y otros
                            servicios de Internet.
                        </li>
                    </ul>

                    En particular, este sitio web utiliza Google Analytics, un servicio analítico de web prestado por
                    Google, Inc. con domicilio en los Estados Unidos con sede central en 1600 Amphitheatre Parkway,
                    Mountain View, California 94043. Para la prestación de estos servicios, Google utiliza cookies que
                    recopilan la información, incluida la dirección IP del usuario, que será transmitida, tratada y
                    almacenada por Google en los términos fijados en la web Google.com. Esto incluye la posible
                    transmisión de dicha información a terceros por razones de exigencia legal o cuando dichos terceros
                    procesen la información por cuenta de Google.
                </div>
                <h4 class="my-2">Gestionar y Rechazar el Uso de Cookies</h4>
                <div class="text">En cualquier momento, puedes adaptar la configuración del navegador para gestionar,
                    desestimar el uso de cookies y ser notificado antes de que se descarguen.
                    También puedes adaptar la configuración de forma que el navegador rechace todas las cookies, o
                    únicamente las cookies de terceros. Y también puedes eliminar cualquiera de las cookies que ya se
                    encuentren en tu equipo.
                    Para esto, debes tener en cuenta que tendrás que adaptar por separado la configuración de cada
                    navegador y equipo que utilices ya que, como te hemos comentado anteriormente las cookies se asocian
                    al navegador, no a la persona.
                    <ul>
                        <li>
                            Google hrome <a href="https://support.google.com/chrome/answer/95647?hl=es-419">https://support.google.com/chrome/answer/95647?hl=es-419</a>  
                        </li>
                        <li>
                            Internet
                            Explorer <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10</a> 
                        </li>
                        <li>
                            Mozilla
                            Firefox <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we</a> 
                        </li>
                        <li>
                            Apple Safari <a href="https://support.apple.com/es-es/HT201265">https://support.apple.com/es-es/HT201265</a>  
                        </li>
                    </ul>

                    Si tienes cualquier duda sobre esta Política de Cookies, puedes contactar con nosotros enviándonos
                    un mail a lopd@borneoadvisors.com
                </div> -->
            </div>
        </div>
    </section>
</div>

<app-footer></app-footer>