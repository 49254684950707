<div class="page-wrapper">
    <app-menu></app-menu>

    <section class="page-title" style="background-image: url(assets/images/Sail-Away.jpg);">
        <div class="auto-container">
            <h1>Sobre nosotros</h1>
            <div class="text">Borneo Advisors es una compañía independiente especializada en los segmentos de oficinas y locales comerciales en los que opera en áreas de inversión y agencia con un enfoque adaptado a perfiles tanto institucionales como privados.
            </div>
            <div class="text">Diseñamos a medida cada proceso de comercialización, ya sea de venta o alquiler de edificios completos o superficies modulares. Proporcionamos un servicio eficiente, tanto para propietarios e inversores como para usuarios que buscan el espacio
                más adecuado para el desarrollo de su propia actividad empresarial.

            </div>

        </div>
    </section>

    <section class="what-we-do">
        <div class="auto-container">

            <div class="row">
                <!-- Video Column -->
                <div class="video-column col-lg-6 col-md-12 col-sm-12">
                    <div class="video-box">
                        <figure class="image"><img src="assets/images/nosotros/our.png" alt=""></figure>
                    </div>
                </div>

                <!-- Content Column -->
                <div class="content-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div class="inner">
                            <h4>Borneo Flex</h4>
                            <p>
                                Borneo Advisors y sus socios han trabajado en los últimos años con diferentes operadores de espacio flexible de oficinas tanto en la búsqueda y apertura de sus centros como en la captación de potenciales usuarios.
                            </p>
                            <p>
                                Nuestro conocimiento del sector y nuestra propia experiencia nos han llevado a crear con dedicación e ilusión un nuevo servicio, <strong>BorneoFlex</strong>, con el objetivo de ayudaros a vosotros compañías, profesionales
                                y particulares en la tarea de entender las oficinas y las nuevos espacios de trabajo.
                            </p>
                            <p>
                                Tenemos muy presente la dificultad que puede representar encontrar el espacio más idóneo para cada proyecto por lo que intentamos simplificar este proceso centralizando gestiones en un único interlocutor con capacidad para ahorrar tiempo y esfuerzos prestando
                                completo asesoramiento en las principales ciudades españolas.
                            </p>
                            <!-- <h4>Nuestra asombrosa historia</h4>
                            <p>Borneo Advisors y sus socios han trabajado en los últimos años con diferentes operadores de co-working en la búsqueda y apertura de sus centros en España. A raíz de nuestro conocimiento del sector y nuestras propia experiencia,
                                hemos creado con dedicación y con mucha ilusión un nuevo servicio, “BORNEO FLEX”. Con el objetivo de ayudaros a vosotros, compañías y empresarios que como nosotros decidimos dar el paso, de confiar en nuevos proyectos y
                                en un nuevo concepto de entender las oficinas y los espacios de trabajo. Tenemos muy presente esta fase en nuestros inicios como compañía y queremos ayudaros a encontrar el espacio ideal, que os permita desarrollar vuestro
                                proyecto, os ofrecemos ahorrar tiempo y esfuerzos, centralizando todas las gestiones a través de un único interlocutor, os asesoraremos de forma gratuita y trabajaremos con los espacios flexibles de los operadores en las
                                principales ciudades de España


                            </p> -->
                        </div>
                    </div>
                </div>
            </div>

            <!--
            <div class="fact-counter col-12">

                <!--Column--
                <div class="counter-column wow fadeInUp col-lg-4 col-md-3 col-6">
                    <div class="count-box">
                        <span class="count-text" data-speed="5000" data-stop="985">+<span id='operaciones'>0</span></span>
                        <h4 class="counter-title">Operaciones</h4>
                    </div>
                </div>

                <!--Column--
                <div class="counter-column wow fadeInUp col-lg-4 col-md-3 col-6" data-wow-delay="400ms">
                    <div class="count-box">
                        <span class="count-text" data-speed="5000" data-stop="796">+<span id='clients'>0</span></span>
                        <h4 class="counter-title">Clientes satisfechos</h4>
                    </div>
                </div>

                <!--Column--
                <div class="counter-column wow fadeInUp col-lg-4 col-md-3 col-6" data-wow-delay="800ms">
                    <div class="count-box">
                        <span class="count-text" data-speed="5000" data-stop="3890">+<span id="transacciones">0</span></span>
                        <h4 class="counter-title">m2 transaccionados</h4>
                    </div>
                </div>

                <!--Column--
                <div class="counter-column wow fadeInUp col-lg-4 col-md-3 col-6" data-wow-delay="1200ms">
                    <div class="count-box">
                        <span class="count-text" data-speed="5000" data-stop="80">+<span id="inmuebles">0</span></span>
                        <h4 class="counter-title">Inmuebles en comercialización</h4>
                    </div>
                </div>
            </div>
            -->
        </div>
    </section>

    <app-howitworks></app-howitworks>
</div>
<app-footer></app-footer>