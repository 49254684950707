<section class="how-it-works">
    <div class="auto-container">
        <div class="sec-title text-center">
            <h2>Vea cómo funciona</h2>
            <span class="divider"></span>
            <div class="text">Descubre cómo funciona nuestro motor de búsqueda en tres sencillos pasos que hemos creado para ayudarte a encontrar tu espacio ideal en poco tiempo.</div>
        </div>
        <div class="row">
            <div class="work-block col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                    <figure class="icon-box"><img src="assets/images/busqueda-de-lupa.png" alt="" class="iconosTamano"></figure>
                    <h4> <a href="#">Elige un lugar</a> </h4>
                    <div class="text tamanoTexto">Sólo tienes que decidir en qué ciudad quieres elegir tu espacio y para cuántas personas, el resto lo hacemos nosotros. </div>
                </div>
            </div>

            <div class="work-block col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                    <figure class="icon-box"><img src="assets/images/edificio-de-oficinas.png" alt="" class="iconosTamano"></figure>
                    <h4><a href="/contacto">Llama a la oficina</a></h4>
                    <div class="text tamanoTexto">Llama a la oficina o rellena el formulario de contacto y nos pondremos en contacto contigo inmediatamente para facilitarte la búsqueda y empezar los trámites de contratación si lo deseas.</div>
                </div>
            </div>

            <div class="work-block col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                    <figure class="icon-box"><img src="assets/images/apreton-de-manos.png" alt="" class="iconosTamano"></figure>
                    <h4>¡Consíguela!</h4>
                    <div class="text tamanoTexto">Si ya has seguido y completado los pasos 1 y 2 estás a punto de conseguir tu nuevo espacio de trabajo donde estarás rodeado de todo lo que buscabas y te sentirás como en casa.</div>
                </div>
            </div>
        </div>
    </div>
</section>