<div class="page-wrapper bg_alice">
    <app-menu></app-menu>

    <section class="page-title" style="background-image: url(assets/images/nosotros/our.png);">
        <div class="auto-container">
            <h1>Contáctanos</h1>
            <!-- <div class="text">Danos al menos 24 horas para contestarte. Si te corre prisa, llámanos</div> -->
            <div class="text">En un plazo máximo de 24 horas daremos respuesta a tu solicitud. Si te urge, llámanos directamente.</div>
        </div>
    </section>

    <section class="contact-section">
        <div class="auto-container">
            <div class="sec-title text-center">
                <h2>¿Alguna duda?</h2>
                <span class="divider"></span>
                <!-- <div class="text">Explore algunos de los mejores consejos de la ciudad de nuestros socios y amigos.</div> -->
                <div class="text">Rellena este formulario</div>
            </div>

            <div class="row">
                <!-- Map Column -->
                <div class="map-column col-lg-7 col-md-12 col-sm-12">
                    <!-- Business Info Widget -->
                    <div class="business-info-widget ls-widget">
                        <div class="widget-title">
                            <h4><span class="icon flaticon-call"></span>Información de contacto</h4>
                        </div>
                        <div class="widget-content">
                            <ul class="contact-info-list">
                                <!-- <li><span class="icon flaticon-pin"></span>Madrid – Spain Paseo de la Castellana, 123 – 3ºB -->
                                <li><span class="icon flaticon-pin"></span>Edificio Cuzco II - Poeta Joan Margall, 38 - 2º -28020 Madrid
                                </li>
                                <li><span class="icon flaticon-call"></span> <a href="tel:61282369200">+34 91 251 98
                                        33</a>
                                </li>
                                <!--<li><span class="icon flaticon-mail"></span> <a
                                        href="mailto:support@listdo.com">support@office.com </a></li>-->
                            </ul>
                            <div class="map-box">
                                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194347.38441074465!2d-3.819619413888939!3d40.43813107957857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422997800a3c81%3A0xc436dec1618c2269!2sMadrid!5e0!3m2!1ses!2ses!4v1616417952551!5m2!1ses!2ses"
                                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1276.355289601829!2d-3.6928420456827746!3d40.459741396252916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPoeta%20Joan%20Margall%2C%2038%20-%202%C2%BA%20-28020%20Madrid!5e0!3m2!1ses!2ses!4v1651233266298!5m2!1ses!2ses"
                                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <!--<ul class="social-icon-two">
                                <li><a href="#"><span class="fab fa-facebook"></span></a></li>
                                <li><a href="#"><span class="fab fa-twitter"></span></a></li>
                                <li><a href="#"><span class="fab fa-instagram"></span></a></li>
                                <li><a href="#"><span class="fab fa-pinterest"></span></a></li>
                                <li><a href="#"><span class="fab fa-dribbble"></span></a></li>
                                <li><a href="#"><span class="fab fa-google"></span></a></li>
                            </ul>-->
                        </div>
                    </div>
                </div>

                <div class="contact-column col-lg-5 col-md-12 col-sm-12">
                    <!-- Contact Widget -->
                    <div class="contact-widget ls-widget">
                        <div class="widget-title">
                            <h4><span class="icon flaticon-list"></span>Contacto de negocio</h4>
                        </div>
                        <div class="widget-content">
                            <!-- Comment Form -->
                            <div class="default-form">
                                <!--Comment Form-->
                                <form [formGroup]="contactGroup" (ngSubmit)="onSubmit()">
                                    <div class="row clearfix ">
                                        <!--<div class="form-group col-lg-12 col-md-12 col-sm-12">
                                            <div class="response"></div>
                                        </div>-->

                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre *" required>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" formControlName="email" class="form-control" placeholder="Email *" required>
                                            <small *ngIf="emailNoValido" class="text-danger">Formato email incorrecto
                                                (ejemplo: ejemplo@gmail.com)</small>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" formControlName="asunto" class="form-control" placeholder="Asunto *" required>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <textarea formControlName="mensaje" placeholder="Mensaje" required></textarea>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-check col-12">
                                                <input type="checkbox" id="check" (change)="comprobador()">
                                                <label class="form-check-label ml-2" for="check">
                                                    He leído y acepto las políticas de privacidad
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <button class="theme-btn btn-style-two" type="submit" name="submit-form">Enviar solicitud</button>
                                            <div *ngIf="politicas==false">
                                                <small class="text-danger">Acepte la politica de privacidad</small>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<app-footer></app-footer>