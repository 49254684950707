<div class="bg-menu border-right " id="sidebar-wrapper">
  <div class="list-group list-group-flush">
    <!-- <div class="sidebar-heading mb-3 mt-3 text-center">O</div> -->
    <a routerLink="adminPropertys" class="list-group-item list-group-item-action bg-menu text-white"><i
        class="fas fa-building font-big"></i></a>
    <a routerLink="listPropertys" class="list-group-item list-group-item-action bg-menu text-white"><i
        class="fas fa-clipboard-list font-big"></i></a>
    <a routerLink="contact" class="list-group-item list-group-item-action bg-menu text-white"><i
        class="fas fa-user font-big"></i></a>
    <a routerLink="contactOwned" class="list-group-item list-group-item-action bg-menu text-white"><i
        class="fas fa-house-user font-big"></i></a>
  </div>
</div>