<div class="page-wrapper bg_alice">
    <app-menu></app-menu>

    <section class="page-title" style="background-image: url(assets/images/nosotros/our.png);">
        <div class="auto-container">
            <h1>¿Desea incluir su espacio de trabajo en nuestra web?</h1>
            <!--<div class="text">Aliquam erat volutpat. Praesent mattis</div>-->
        </div>
    </section>

    <section class="how-it-works-two">
        <div class="auto-container">
            <div class="sec-title text-center">
                <h2>Cómo funciona</h2>
                <span class="divider"></span>
                <div class="text">Añada su negocio, nuestros clientes podrán buscarlo fácilmente.</div>
            </div>

            <div class="blocks-outer">
                <!-- Work Block Two-->
                <div class="work-block-two">
                    <div class="content-box d-flex flex-row">
                        <div class="inner">
                            <span class="count">01</span>
                            <h4>Envíe el formulario</h4>
                            <!-- <div class="text">Publicite su espacio gratis en nuestra plataforma. Llegará a más usuarios.
                                Nosotros sólo cobraremos porcentaje de intermediación previamente negociado.</div> -->
                            <div class="text">
                                Publique su espacio en nuestra Plataforma. Llegará a más usuarios.
                            </div>
                        </div>
                        <div class="inner">
                            <span class="count">02</span>
                            <!-- <h4>Espere 24 horas</h4> -->
                            <h4>Antes de 24 horas</h4>
                            <div class="text">Le contactaremos personalmente para estudiar su caso concreto.
                            </div>
                        </div>
                        <div class="inner">
                            <span class="count">03</span>
                            <h4>¡Reciba una respuesta!</h4>
                            <div class="text">Le detallaremos procedimiento más adecuado. Con su conformidad, nos ponemos en marcha!!</div>
                        </div>
                    </div>
                </div>


                <div class="contact-column col-lg-12 col-md-12 col-sm-12">
                    <!-- Contact Widget -->
                    <div class="contact-widget ls-widget">
                        <div class="widget-title">
                            <h4><span class="icon flaticon-list"></span>Contacto de negocio</h4>
                        </div>
                        <div class="widget-content">
                            <!-- Comment Form -->
                            <div class="default-form">
                                <!--Comment Form-->
                                <form [formGroup]="propertyForm" (ngSubmit)="onSubmit()">
                                    <div class="row clearfix ">
                                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                            <div class="response"></div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre *" required>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="email" formControlName="email" class="form-control" placeholder="Tu email *" required>
                                            <small *ngIf="emailNoValido" class="text-danger">Formato email incorrecto
                                                (ejemplo: ejemplo@gmail.com)</small>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" formControlName="telefono" class="form-control" placeholder="Teléfono *" required>
                                            <small *ngIf="telefonoNoValido" class="text-danger">Formato telefono
                                                incorrecto (ejemplo: 123456789)</small>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" #search formControlName="ciudad" class="form-control" placeholder="Ciudad interes *" required>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" formControlName="tarifas" class="form-control" placeholder="Tarifas *" required>
                                            <small *ngIf="tarifasNoValido" class="text-danger">Solo números (ejemplo:
                                                500)</small>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" formControlName="espacios" class="form-control" placeholder="Espacios *" required>
                                            <small *ngIf="EspaciosNoValido" class="text-danger">Número de espacios
                                                (ejemplo: 5)</small>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-check col-12">
                                                <input type="checkbox" id="check" (change)="comprobador()">
                                                <label class="form-check-label ml-2" for="check">
                                                    He leído y acepto las políticas de privacidad
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <button class="theme-btn btn-style-two" type="submit" id="submit" name="submit-form">Enviar solicitud</button>
                                            <div *ngIf="politicas==false">
                                                <small class="text-danger">Acepte la politica de privacidad</small>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



</div>
<app-footer></app-footer>