<app-menu-top></app-menu-top>
<div class="d-flex" id="wrapper">
    
    <app-menu-lateral></app-menu-lateral>
    
    <div id="page-content-wrapper">
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>