<router-outlet></router-outlet>

<!--<div id="cajacookies" class="fixed-bottom" *ngIf="cookies==false">
    <p class="text-center">
        <button class="pull-right" (click)=cerrarBannerCookies()>
            Aceptar todas las cookies
        </button>

        En nuestro sitio web, hacemos uso de cookies funcionales, analíticas y de seguimiento para poder ofrecerte la
        mejor experiencia posible. <a href="/politicaCookies">¿estás de acuerdo? Política de Cookie</a>.
    </p>
</div> -->