<div class="page-wrapper" *ngFor="let propiedad of propiedad">
    <app-menu></app-menu>

    <section class="listing-banner">
        <div class="background-layer" style="background-image: url(assets/images/view/office1.png);" *ngIf="urlImage=='default'"></div>
        <div class="background-layer" style="background-image: url({{urlImage}});" *ngIf="urlImage!='default'"></div>

        <div class="auto-container">
            <!-- Content Box -->
            <div class="content-box">
                <!--
                <div class="rating">
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                </div>-->
                <h1 class="display-4">{{propiedad.nombre}}
                    <!--<span class="icon icon-verified"></span>-->
                </h1>
                <!--<div class="text">{{propiedad.descripcion}}</div>-->
            </div>

            <!-- Listing Options -->
            <div class="listing-options">
                <ul class="options-list">
                    <li><a (click)="scroll(target)"><span class="icon flaticon-compass"></span> Fotos</a></li>
                    <li><a (click)="abrirModalcall(propiedad.telefono)"><span class="icon flaticon-phone-call-1"></span> LLamar
              ahora</a></li>
                    <li><a href="/contacto"><span class="icon flaticon-unlink"></span> Contratar</a></li>
                    <!--<li><a href="report-listing.html" class="call-modal"><span class="icon flaticon-flag"></span> Reportar </a></li>-->
                </ul>
            </div>
        </div>
    </section>

    <div class="sidebar-page-container bg_alice">
        <div class="auto-container">
            <div class="row">
                <!--Content Side-->
                <div class="content-side col-lg-8 col-md-12 col-sm-12">
                    <!-- Listing Single -->
                    <div class="listing-single">
                        <!-- Description Widget -->
                        <div class="description-widget ls-widget">
                            <div class="widget-title">
                                <h4><span class="icon flaticon-menu"></span> Descripción</h4>
                            </div>
                            <div class="widget-content">
                                <p>{{propiedad.descripcion}}</p>

                            </div>
                        </div>

                        <!-- Features Widget -->
                        <div class="features-widget ls-widget">
                            <div class="widget-title">
                                <h4><span class="icon flaticon-list"></span> Características de la lista</h4>
                            </div>
                            <div class="widget-content">
                                <ul class="listing-features">
                                    <li *ngIf="propiedad.access=='true'"><i class="fas fa-stopwatch icon"></i> Acceso 24/7
                                    </li>
                                    <!------------------------------------------------------------------------------------------->
                                    <li *ngIf="propiedad.mobiliario=='true'"><i class="fas fa-building icon"></i> Mobiliario
                                    </li>
                                    <li *ngIf="propiedad.suministros=='true'"><i class="fas fa-box icon"></i> Suministros
                                    </li>
                                    <li *ngIf="propiedad.networking=='true'"><i class="fas fa-ethernet icon"></i> Networking
                                    </li>
                                    <li *ngIf="propiedad.skype=='true'"><i class="fab fa-skype icon"></i> Skype
                                    </li>
                                    <li *ngIf="propiedad.guardabicis=='true'"><i class="fas fa-bicycle icon"></i> Guardabicis
                                    </li>
                                    <li *ngIf="propiedad.cantina=='true'"><i class="fas fa-glass-martini icon"></i> Cantina
                                    </li>
                                    <li *ngIf="propiedad.impresora=='true'"><i class="fas fa-print icon"></i> Impresora
                                    </li>
                                    <li *ngIf="propiedad.gimnasio=='true'"><i class="fas fa-dumbbell icon"></i> Gimnasio
                                    </li>
                                    <!-------------------------------------------------------------------------------------------->
                                    <li *ngIf="propiedad.salas_reuniones=='true'"><i class="fas fa-handshake icon"></i> Salas de reuniones</li>
                                    <li *ngIf="propiedad.reception=='true'"><i class="fas fa-concierge-bell icon"></i> Recepción
                                    </li>
                                    <li *ngIf="propiedad.eventos_network=='true'"><i class="fas fa-calendar icon"></i> Eventos-network
                                    </li>
                                    <li *ngIf="propiedad.terraza=='true'"><i class="fas fa-cocktail icon"></i> Terraza
                                    </li>
                                    <li *ngIf="propiedad.cafe_relax=='true'"><i class="fas fa-coffee icon"></i> Zona café/relax
                                    </li>
                                    <li *ngIf="propiedad.seguridad=='true'"><i class="fas fa-shield-alt icon"></i> Seguridad 24 horas</li>
                                    <li *ngIf="propiedad.limpieza=='true'"><i class="fas fa-soap icon"></i> Limpieza
                                    </li>
                                    <li *ngIf="propiedad.cer_energetica=='true'"><i class="fas fa-stamp icon"></i> Certificado energético</li>
                                    <li *ngIf="propiedad.paqueteria=='true'"><i class="fas fa-box icon"></i> Paquetería
                                    </li>
                                    <li *ngIf="propiedad.parking=='true'"><i class="fas fa-parking icon"></i> Parking
                                    </li>
                                    <li *ngIf="propiedad.wifi=='true'"><i class="fas fa-wifi icon"></i> Wifi</li>
                                    <li *ngIf="propiedad.coworking=='true'"><i class="far fa-building icon"></i> Coworking
                                    </li>
                                    <!--CAMBIAR ICONOS DE ESTOS TRES ULTIMOS-->
                                    <li *ngIf="propiedad.oficina_privada=='true'"><i class="fas fa-building icon"></i> Oficina privada</li>
                                    <li *ngIf="propiedad.puesto_fijo=='true'"><i class="fas fa-toolbox icon"></i> Puesto permanente
                                    </li>
                                    <li *ngIf="propiedad.puesto_flexible=='true'"><i class="far fa-building icon"></i> Puesto no fijo</li>
                                </ul>
                            </div>
                        </div>

                        <!-- Gallery Widget -->
                        <div class="gallery-widget ls-widget">
                            <div class="widget-title">
                                <h4><span class="icon flaticon-gallery"></span> Galería</h4>
                            </div>
                            <div class="widget-content">
                                <ul class="listing-gallery">
                                    <!-- Gallery Item -->
                                    <li class="gallery-item" *ngFor="let imagen of images">
                                        <div class="inner-box">
                                            <figure class="image"><img src="http://borneoflex.es/borneo/uploads/{{imagen.imagen}}" alt="">
                                            </figure>

                                            <div class="overlay">
                                                <a (click)="abrirModal(imagen.imagen)" class="lightbox-image"><span
                            class="icon flaticon-magnifying-glass"></span>
                        </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- 
                        <div class="average-reviews-widget ls-widget">
                            <div class="widget-title"><h4><span class="icon flaticon-star-1"></span> Media de Reviews</h4></div>
                            <div class="widget-content">
                                <div class="review-outer">
                                    
                                    <div class="review-box">
                                        <h1>4.7</h1>
                                        <span class="title">Fuera de 5.0</span>
                                        <ul class="rating">
                                            <li><span class="fa fa-star"></span></li>
                                            <li><span class="fa fa-star"></span></li>
                                            <li><span class="fa fa-star"></span></li>
                                            <li><span class="fa fa-star"></span></li>
                                            <li><span class="fa fa-star"></span></li>
                                        </ul>
                                    </div>

                                        <div class="rating-bars-item">
                                            <span class="rating-bars-name">Quality</span>
                                            <span class="rating-bars-inner">
                                                <span class="rating-bars-rating" data-rating="4.8">
                                                    <span class="rating-bars-rating-inner"></span>
                                                </span>
                                                <strong>4.8</strong>
                                            </span>
                                        </div>

                                        
                                        <div class="rating-bars-item">
                                            <span class="rating-bars-name">Localización</span>
                                            <span class="rating-bars-inner">
                                                <span class="rating-bars-rating" data-rating="1.4">
                                                    <span class="rating-bars-rating-inner"></span>
                                                </span>
                                                <strong>1.4</strong>
                                            </span>
                                        </div>

                                        
                                        <div class="rating-bars-item">
                                            <span class="rating-bars-name">Espacios</span>
                                            <span class="rating-bars-inner">
                                                <span class="rating-bars-rating" data-rating="3.2">
                                                    <span class="rating-bars-rating-inner"></span>
                                                </span>
                                                <strong>3.2</strong>
                                            </span>
                                        </div>

                                        
                                        <div class="rating-bars-item">
                                            <span class="rating-bars-name">Servicio</span>
                                            <span class="rating-bars-inner">
                                                <span class="rating-bars-rating" data-rating="5.0">
                                                    <span class="rating-bars-rating-inner"></span>
                                                </span>
                                                <strong>5.0</strong>
                                            </span>
                                        </div>

                                        
                                        <div class="rating-bars-item">
                                            <span class="rating-bars-name">Precio</span>
                                            <span class="rating-bars-inner">
                                                <span class="rating-bars-rating" data-rating="4.8">
                                                    <span class="rating-bars-rating-inner"></span>
                                                </span>
                                                <strong>4.8</strong>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </div>

                <!-- Sidebar Side -->
                <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside class="sidebar">

                        <!-- Business Info Widget -->
                        <div class="business-info-widget ls-widget">
                            <div class="widget-title">
                                <h4><span class="icon flaticon-menu"></span>Información de contacto</h4>
                            </div>
                            <div class="widget-content">

                                <ul class="contact-info-list">
                                    <li><span class="icon flaticon-pin"></span>{{propiedad.ciudad}}</li>
                                    <li><span class="icon flaticon-call"></span> <a href="tel:61282369200">{{propiedad.telefono}} </a>
                                    </li>
                                    <!--<li><span class="icon flaticon-mail"></span> <a href="mailto:support@listdo.com">info@info.es </a></li>-->
                                </ul>

                            </div>
                        </div>

                        <!-- 
                        <div class="price-range-widget ls-widget">
                            <div class="widget-title">
                                <h4><span class="icon flaticon-menu"></span>Rango de precio</h4>
                            </div>
                            <div class="widget-content">
                                <div class="range-slider-one clearfix">
                                    <div class="price-range-slider"></div>
                                    <div class="btn-outer">
                                        <div class="input-box">
                                            <div class="title">Price:</div>
                                            <div class="input"><input type="text" class="property-amount" name="field-name" readonly>{{propiedad.tarifa}}</div>
                                        </div>
                                        <div class="btn-box">
                                            <a href="#" class="theme-btn btn-style-two">Filtrar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->

                        <!-- Categories Widget -->
                        <!-- <div class="categories-widget ls-widget">
                            <div class="widget-title"><h4><span class="icon flaticon-menu"></span>Categories</h4></div>
                            <div class="widget-content">
                                <ul class="categories-list">
                                    <li class="rest"><a href="#"><span class="icon flaticon-chef"></span> Law</a></li>
                                    <li class="art"><a href="#"><span class="icon flaticon-museum-1"></span> Big</a></li>
                                </ul>
                            </div>
                        </div> -->
                        <!-- 
            <div class="nearby-widget ls-widget">
              <div class="widget-title" #target>
                <h4><span class="icon flaticon-map"></span> Oficinas similares</h4>
              </div>
              <div class="widget-content">
                <div class="listing-block-six" *ngFor="let propiedad of propiedadesFiltradas">
                  <div class="inner-box">
                    <div class="image-box">
                      <figure class="image"><a href="listing-single.html"><img
                            src="assets/images/view/office1-small.png" alt=""></a></figure>
                    </div>
                    <div class="content">
                      <h3><a href="listing-single.html">{{propiedad.nombre}} <span class="icon icon-verified"></span>
                        </a></h3>
                      <ul class="info">
                        <li><span class="flaticon-pin"></span> {{propiedad.ciudad}}</li>
                      </ul>
                      
                                            <div class="rating">
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                            </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
                        <!-- Widget prizes -->
                        <div class="nearby-widget ls-widget">
                            <div class="widget-title" #target>
                                <h4><span class="icon flaticon-map"></span> Espacios disponibles</h4>
                            </div>

                            <div *ngIf="propiedad.no_disponible == 'true'" class="widget-content prizes-component">
                                <div class="row-types mt-3" *ngIf="propiedad.puesto_flexible=='true'">
                                    <div class="name">Puesto no fijo</div>



                                    <div>
                                        <button class="btn btn-info" routerLink="/contacto">Consultar Disponibilidad</button>
                                    </div>

                                </div>
                                <div class="row-types mt-3" *ngIf="propiedad.puesto_fijo=='true'">
                                    <div class="name">Puesto permanente</div>

                                </div>
                                <div class="row-types mt-3" *ngIf="propiedad.oficina_privada=='true'">
                                    <div class="name">Oficina privada</div>


                                </div>
                            </div>
                            <div *ngIf="propiedad.no_disponible == 'false' || !propiedad.no_disponible" class="widget-content prizes-component">
                                <div class="row-types mt-3" *ngIf="propiedad.puesto_flexible=='true'">
                                    <div class="name">Puesto no fijo</div>
                                    <div class="range"><i class="fas fa-user-alt icon-mr"></i>{{propiedad.rango_puesto_flexible}}</div>

                                    <div *ngIf="propiedad.precio_puesto_flexible!=0">
                                        <div class="price">Desde {{propiedad.precio_puesto_flexible}}/mes</div>
                                    </div>

                                    <div *ngIf="propiedad.precio_puesto_flexible==0">
                                        <button class="btn btn-info" routerLink="/contacto">Consultar</button>
                                    </div>

                                </div>
                                <div class="row-types mt-3" *ngIf="propiedad.puesto_fijo=='true'">
                                    <div class="name">Puesto permanente</div>
                                    <div class="range"><i class="fas fa-user-alt icon-mr"></i>{{propiedad.rango_oficina_fija}}</div>

                                    <div *ngIf="propiedad.precio_oficina_fija!=0">
                                        <div class="price">Desde {{propiedad.precio_oficina_fija}}/mes</div>
                                    </div>

                                    <div *ngIf="propiedad.precio_oficina_fija==0">
                                        <button class="btn btn-info" routerLink="/contacto">Consultar</button>
                                    </div>

                                </div>
                                <div class="row-types mt-3" *ngIf="propiedad.oficina_privada=='true'">
                                    <div class="name">Oficina privada</div>
                                    <!--<div class="range"><i class="fas fa-user-alt icon-mr"></i>{{propiedad.rango_oficina_privada}}</div>-->

                                    <div *ngIf="propiedad.precio_oficina_privada!=0">
                                        <div class="price">Desde {{propiedad.precio_oficina_privada}}/mes</div>
                                    </div>

                                    <div *ngIf="propiedad.precio_oficina_privada==0">
                                        <button class="btn btn-info" routerLink="/contacto">Consultar</button>
                                    </div>

                                </div>
                            </div>

                            <div class="nearby-widget ls-widget">
                                <div class="widget-title" #target>
                                    <h4><span class="icon flaticon-map"></span> Ubicación</h4>
                                </div>
                                <div class="widget-content">
                                    <div class="maps-localization" *ngIf='showMaps'>
                                        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeId]="mapTypeId" id="map" [styles]='styles' (mapReady)="mapReadyHandler($event)">
                                            <agm-marker *ngFor="let marker of markers" [latitude]="marker.position.lat" [longitude]="marker.position.lng" [label]="marker.label" [zoom]="zoom">
                                            </agm-marker>
                                        </agm-map>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </aside>
                </div>
            </div>
        </div>
    </div>


    <app-footer></app-footer>

</div>

<ng-template #modalContent let-close="close">
    <!--<div class="modal-header">
    <button type="button" aria-label="Close" (click)="cerrarModal()" class="posicionBoton">
      <i class="far fa-times-circle coloresIcono"></i>
    </button>
  </div>-->
    <div class="modal-body">
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let imagen of images">
                <div class="img-block w-100">
                    <img src="http://borneoflex.es/borneo/uploads/{{imagen.imagen}}" alt="Img 1" class="centrar rounded">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</ng-template>

<ng-template #modalllamar let-close="close">
    <div class="modal-header">
        <h2 class="text-center">Contáctanos si tienes cualquier duda o consulta</h2>
    </div>
    <div class="modal-body">
        <h4 class="text-center text-dark">{{llamar}}</h4>
    </div>
</ng-template>