<div class="page-wrapper bg_alice">
    <app-menu></app-menu>

    <section class="page-title" style="background-image: url(assets/images/nosotros/our.png);">
        <div class="auto-container">
            <h1>Política de privacidad</h1>
        </div>
    </section>

    <section class="what-we-do pb-2">
        <div class="auto-container">
            <div class="sec-title">

                <h4 class="my-2">Política de privacidad</h4>
                <div class="text">
                    La presente Política de Privacidad regula el uso del servicio del portal de Internet con dirección www.borneoadvisors.com, cuyo responsable a efectos identificativos es:
                </div>
                <ul class="text">
                    <li>
                        - RESPONSABLE: BORNEO REAL ESTATE, S.L.
                    </li>
                    <li>
                        - CIF: B88527684
                    </li>
                    <li>
                        - Dirección: Edificio Cuzco II C/ Poeta Joan Maragall, 38 – 2º izquierda, 28020, Madrid
                    </li>
                    <li>
                        - Teléfono: 912 519 833
                    </li>
                    <li>
                        - Mail: lopd@borneoadvisors.com
                    </li>
                </ul>

                <h4 class="my-2">Finalidad, ¿Para qué tratamos sus datos?</h4>
                <div class="text">
                    A efecto de lo previsto en RGPD de 27 de abril de 2016, y la LO 3/2018, trataremos los datos que recojamos para las siguientes finalidades:
                </div>
                <ul class="text">
                    <li>
                        - Contactar con usted en caso de que así nos lo pida.
                    </li>
                    <li>
                        - Gestionar su navegación en la web, en su caso.
                    </li>
                    <li>
                        - Remitirle comunicaciones comerciales acerca de nuestros productos y servicios.
                    </li>
                </ul>
                <div class="text">
                    Para ello el usuario dispondrá de un formulario de registro cuya cumplimentación legitimará al interesado al acceso y disfrute de determinados servicios ofrecidos en la web.
                </div>
                <div class="text">
                    No obstante, se informa al usuario que en todo formulario de contacto el mismo será informado del uso que se hará de los datos obtenidos y, si son utilizados para un fin distinto, dicho consentimiento se obtendrá previamente y de manera expresa.
                </div>
                <div class="text">
                    Si el consentimiento del interesado se da en el contexto de una declaración escrita que también se refiera a otros asuntos, la solicitud de consentimiento se presentará de tal forma que se distinga claramente de los demás asuntos, de forma inteligible
                    y de fácil acceso y utilizando un lenguaje claro y sencillo, de conformidad con el artículo 7.2 del RGPD de 27 de abril de 2016.
                </div>

                <h4 class="my-2">Legitimación, ¿Cuál es la legitimación para el tratamiento de sus datos?</h4>
                <div class="text">
                    La base legal para el tratamiento de sus datos se basará en:
                </div>
                <ul class="text">
                    <li>
                        - El consentimiento del interesado, para el tratamiento de sus datos, conforme el artículo 6. 1. a) del Reglamento General Europeo de Protección de Datos.
                    </li>
                    <li>
                        - La necesaria ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de este de medidas precontractuales, conforme el artículo 6. 1. b) del Reglamento General Europeo de Protección de Datos.
                    </li>
                </ul>

                <h4 class="my-2">Destinatarios ¿A quién comunicamos tus datos?</h4>
                <div class="text">
                    Los datos personales no serán objeto de cesión salvo en el cumplimiento de obligaciones legalmente establecidas o para dar soporte a los servicios vinculados a este tratamiento.
                </div>

                <h4 class="my-2">Conservación, ¿Durante cuánto tiempo conservamos tus datos?</h4>
                <div class="text">
                    Conservaremos sus datos de carácter personal durante al menos 5 años en caso de que sea cliente y para el estricto cumplimiento de las obligaciones legales establecidas en la normativa.
                </div>
                <div class="text">
                    En caso de no ser cliente conservaremos sus datos el mínimo tiempo indispensable para gestionar nuestra relación.
                </div>

                <h4 class="my-2">Derechos, ¿Cuáles son tus derechos cuando nos facilitas tus datos?</h4>
                <div class="text">
                    Cualquier persona tiene derecho a obtener confirmación sobre si se están tratando datos personales que le conciernan.
                </div>
                <div class="text">
                    Las personas interesadas tienen derecho a poder acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines
                    que fueron recogidos.
                </div>
                <div class="text">
                    En determinadas circunstancias, las personas interesadas podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
                </div>
                <div class="text">
                    En determinadas circunstancias y por motivos relacionados con su situación particular, las personas interesadas podrán oponerse al tratamiento de sus datos. En tal caso, dejarán de tratarse los datos, salvo por motivos legítimos o el ejercicio o la defensa
                    de posibles reclamaciones.
                </div>
                <div class="text">
                    Las personas interesadas podrán solicitar la portabilidad de los datos para obtener los datos que han proporcionado en un formato estructurado, de uso común y de lectura mecánica, para ser descargados por sí mismos o transmitidos a otra entidad.
                </div>
                <div class="text">
                    La persona interesada puede ejercitar sus derechos dirigiendo un correo electrónico a la dirección de correo electrónico lopd@borneoadvisors.com, o en su caso a la dirección física Edificio Cuzco II C/ Poeta Joan Maragall, 38 - 2º izquierda, 28020, Madrid.
                </div>

                <h4 class="my-2">Consentimiento menores, ¿Qué pasa si eres menor de edad?</h4>
                <div class="text">
                    En el supuesto que algunos de nuestros servicios vayan dirigidos específicamente a menores de catorce años, solicitaremos la conformidad de los padres o tutores para la recogida de los datos personales o, en su caso, para el tratamiento automatizado de
                    los datos conforme el artículo 7 de la LOPDGDD.
                </div>

                <h4 class="my-2">Exactitud, veracidad y seguridad de los datos, ¿Cómo tratamos tus datos?</h4>
                <div class="text">
                    El Usuario es el único responsable de la veracidad y corrección de los datos incluidos, exonerándonos de cualquier responsabilidad al respecto. Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos
                    personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario acepta proporcionar información completa y correcta en los formularios de registro o suscripción. No responderemos de la veracidad de las informaciones
                    que no sean de elaboración propia y de las que se indique otra fuente, por lo que tampoco asume responsabilidad alguna en cuanto a hipotéticos perjuicios que pudieran originarse por el uso de dicha información.
                </div>
                <div class="text">
                    También reservaremos el derecho a actualizar, modificar o eliminar la información contenida en la web pudiendo incluso limitar o no permitir el acceso a dicha información. No seremos responsables ante cualquier daño o perjuicio que pudiera sufrir el Usuario
                    como consecuencia de errores, defectos u omisiones, en la información facilitada por el responsable siempre que proceda de fuentes ajenas.
                </div>
                <div class="text">
                    Los datos serán tratados de forma confidencial y bajo el sometimiento a medidas técnicas y organizativas de seguridad adecuadas para evitar su alteración, pérdida, tratamiento o acceso no autorizado.
                </div>
                <div class="text">
                    Podrá presentar una reclamación ante la Agencia Española de Protección de Datos, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, en la dirección postal y/o electrónica indicada en la página www.aepd.es.
                </div>


                <!-- <h4 class="my-2">Política de privacidad</h4>
                <div class="text">La presente Política de Privacidad regula el uso del servicio del portal de Internet http://borneoflex.es/ (en adelante, el «Web») de BORNEO REAL ESTATE, S.L. con domicilio social en C/ SERRANO, 98, ESCALERA 2, 3 DERECHA, MADRID, 28006,
                    MADRID con CIF B88527684.
                </div>
                <h4 class="my-2">Confidencialidad y Protección de Datos</h4>
                <div class="text">A efecto de lo previsto en RGPD de 27 de abril de 2016, y la LO 3/2018, de 5 de diciembre (LOPDGDD) BORNEO REAL ESTATE, S.L. informa al Usuario de la existencia de un tratamiento automatizado de datos de carácter personal creado por y
                    para BORNEO REAL ESTATE, S.L. y bajo su responsabilidad, con la finalidad de realizar el mantenimiento y gestión de la relación con el Usuario, así como las labores de información. En el momento de la aceptación de las presentes condiciones
                    generales, BORNEO REAL ESTATE, S.L. precisará del Usuario la recogida de unos datos imprescindibles para la prestación de sus servicios.
                </div>
                <h4 class="my-2">Registro de ficheros y formularios</h4>
                <div class="text">La cumplimentación del formulario de registro es obligatoria para acceder y disfrutar de determinados servicios ofrecidos en la web. El no facilitar los datos personales solicitados o el no aceptar la presente política de protección de
                    datos supone la imposibilidad de suscribirse, registrarse o participar en cualquiera de las promociones en las que se soliciten datos carácter personal. A efecto de lo previsto en RGPD de 27 de abril de 2016, y la LO 3/2018, de 5 de
                    diciembre (LOPDGDD) le informamos que los datos personales que se obtengan como consecuencia de su registro como Usuario, serán incorporados a un fichero titularidad de BORNEO REAL ESTATE, S.L. con C.I.F B88527684 y domicilio en C/
                    SERRANO, 98, ESCALERA 2, 3 DERECHA, MADRID, 28006, MADRID, teniendo implementadas las medidas de seguridad establecidas en la normativa vigente objeto de aplicación.
                </div>
                <h4 class="my-2">Exactitud y veracidad de los datos facilitados</h4>
                <div class="text">El Usuario es el único responsable de la veracidad y corrección de los datos incluidos, exonerándose BORNEO REAL ESTATE, S.L. de cualquier responsabilidad al respecto. Los usuarios garantizan y responden, en cualquier caso, de la exactitud,
                    vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de registro o suscripción. BORNEO
                    REAL ESTATE, S.L. no responde de la veracidad de las informaciones que no sean de elaboración propia y de las que se indique otra fuente, por lo que tampoco asume responsabilidad alguna en cuanto a hipotéticos perjuicios que pudieran
                    originarse por el uso de dicha información.  BORNEO REAL ESTATE, S.L. se reserva el derecho a actualizar, modificar o eliminar la información contenida en sus páginas web pudiendo incluso limitar o no permitir el acceso a dicha información.
                    Se exonera a BORNEO REAL ESTATE, S.L. de responsabilidad ante cualquier daño o perjuicio que pudiera sufrir el Usuario como consecuencia de errores, defectos u omisiones, en la información facilitada por BORNEO REAL ESTATE, S.L. siempre
                    que proceda de fuentes ajenas a BORNEO REAL ESTATE, S.L..
                </div>
                <h4 class="my-2">Cookies</h4>
                <div class="text">El sitio http://borneoflex.es/ utiliza cookies, considerando tales ficheros físicos de información alojados en el propio terminal del usuario y sirven para facilitar la navegación del usuario por el portal. De todas formas, el usuario
                    tiene la posibilidad de configurar el navegador de tal modo que impida la instalación de estos archivos.
                </div>
                <h4 class="my-2">Finalidades</h4>
                <div class="text">Las finalidades de BORNEO REAL ESTATE, S.L. son el mantenimiento y gestión de la relación con el Usuario, así como las labores de información.
                </div>
                <h4 class="my-2">Menores de edad</h4>
                <div class="text">En el supuesto que algunos de nuestros servicios vayan dirigidos específicamente a menores de edad, BORNEO REAL ESTATE, S.L. solicitará la conformidad de los padres o tutores para la recogida de los datos personales o, en su caso, para
                    el tratamiento automatizado de los datos.
                </div>
                <h4 class="my-2">Cesión de datos a terceros</h4>
                <div class="text">BORNEO REAL ESTATE, S.L. no realizará cesión de datos de los usuarios a terceros.
                </div>
                <h4 class="my-2">Ejercicio de derechos de acceso, rectificación, cancelación y oposición</h4>
                <div class="text">Podrá dirigir sus comunicaciones y ejercitar los derechos de acceso, rectificación, supresión, limitación, portabilidad y oposición en la dirección de Internet a www.borneoadvisors.com o bien por correo electrónico a  lopd@borneoadvisors.com dirigido
                    a BORNEO REAL ESTATE, S.L., Ref. RGPD. Para ejercer dichos derechos es necesario que usted acredite su personalidad frente a BORNEO REAL ESTATE, S.L. mediante el envío de fotocopia de Documento Nacional de Identidad o cualquier otro
                    medio válido en Derecho. No obstante, la modificación o rectificación de sus datos de registro se podrá realizar en el propio Site identificándose, previamente, con su usuario y contraseña.
                </div>
                <h4 class="my-2">Medidas de seguridad</h4>
                <div class="text">BORNEO REAL ESTATE, S.L.   ha adoptado los niveles de seguridad de protección de los Datos Personales legalmente requeridos, y procuran instalar aquellos otros medios y medidas técnicas adicionales a su alcance para evitar la pérdida,
                    mal uso, alteración, acceso no autorizado y robo de los Datos Personales facilitados a BORNEO REAL ESTATE, S.L. no será responsable de posibles daños o perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones,
                    virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo de este sistema electrónico, motivadas por causas ajenas a BORNEO REAL ESTATE, S.L.; de retrasos o bloqueos en el uso del presente sistema electrónico
                    causados por deficiencias o sobrecargas de líneas telefónicas o sobrecargas en el Centro de Procesos de Datos, en el sistema de Internet o en otros sistemas electrónicos, así como de daños que puedan ser causados por terceras personas
                    mediante intromisiones ilegítimas fuera del control de BORNEO REAL ESTATE, S.L.. Ello, no obstante, el Usuario debe ser consciente de que las medidas de seguridad en Internet no son inexpugnables.
                </div>
                <h4 class="my-2">Aceptación y consentimiento</h4>
                <div class="text">El usuario declara haber sido informado de las condiciones sobre protección de datos personales, aceptando y consintiendo el tratamiento automatizado de los mismos por parte de BORNEO REAL ESTATE, S.L., en la forma y para las finalidades
                    indicadas en la presente Política de Protección de Datos Personales. Ciertos servicios prestados en el Portal pueden contener condiciones particulares con previsiones específicas en materia de protección de Datos Personales.
                </div> -->
            </div>
        </div>
    </section>
</div>

<app-footer></app-footer>