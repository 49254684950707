<!-- Main Footer -->
<footer class="main-footer">

    <!-- Footer Content -->
    <!--<div class="footer-content">
        <div class="auto-container">
            <ul class="social-icon-one">
                <li><a href="#"><span class="fab fa-facebook"></span></a></li>
                <li><a href="#"><span class="fab fa-twitter"></span></a></li>
                <li><a href="#"><span class="fab fa-instagram"></span></a></li>
            </ul>
        </div>
    </div>-->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="text">&copy; BorneoFlex 2021 - Todos los derechos reservados, <a href="/politicaPrivacidad">
        Politica de privacidad </a>, <a href="/avisoLegal">
            Aviso legal </a>, <a href="/politicaCookies">Politica de cookies</a> </div>
    </div>

    <!-- Scroll To Top -->
    <div class="scroll-to-top scroll-to-target" data-target="html"><span class="flaticon-up"></span></div>
</footer>
<!-- End Footer -->
