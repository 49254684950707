<!-- <header class="main-header header-style-two">
        
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
            <a class="navbar-brand" href="#">
                <img [src]="logo" width="200" height="60" class="d-inline-block align-top" alt="">
              </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active">
                  <a class="nav-link" href="#">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Contact</a>
                </li>
              </ul>
            </div>
        </div>
    </nav>

</header> -->
<!--
<header class="main-header">
  <div class="main-box">  
      <nav class="nav main-menu navbar navbar-expand-lg">
        <div class="logo-box">
          <div class="logo"><a href="index.html"><img src="assets/images/logo-7.png" alt="" title=""></a></div>
        </div>
        <div class="nav-outer">
        <button class="navbar-toggler color" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fas fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navigation navbar-nav">
            <li class="nav-item"><a class="nav-link" href="/">Inicio</a></li>
            <li class="nav-item"><a class="nav-link" href="/nosotros">Quiénes somos</a></li>
            <li class="nav-item"><a class="nav-link" href="/contacto">Contacto</a></li>
            <li class="nav-item"><a class="nav-link" href="#">Preguntas frecuentes</a></li>
          </ul>

          <div class="outer-box">
            <a class="nav-link" href="/submit" class="add-listing"> <span class="flaticon-plus-symbol"></span> PUBLIQUE
              SU ESPACIO</a>
          </div>
        </div>

      </nav>

  </div>
</header>-->

<div class="container-fluid">
    <div class="row">
        <nav class="nav main-menu navbar navbar-expand-lg navbar-dark col-12">
            <div class="separacion col-auto">
                <div class="logo separacion">
                    <a href="index.html"><img class="img" src="assets/images/Borneo_Negativo_Blanco2.png" alt="" title=""></a>
                </div>
            </div>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

            <div class="collapse navbar-collapse col-lg-auto col-md-auto justify-content-lg-end" id="navbarNav">
                <ul class="navegacion navbar-nav">
                    <li><a href="/">Inicio</a></li>
                    <li><a href="/nosotros">Quiénes somos</a></li>
                    <li><a href="/contacto">Contacto</a></li>
                    <!-- <li><a href="/faqs">Preguntas frecuentes</a></li> -->
                    <li><a href="/faqs">Conceptos</a></li>
                    <li>
                        <a href="/submit" class="boton"> <span class="flaticon-plus-symbol"></span> PUBLIQUE SU ESPACIO</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>