<div class="dashboard-outer">

    <!--FORMULARIO -->
    <div class="col-12">

        <form [formGroup]="propertyForm" (ngSubmit)="onSubmit()" class="overflow-auto">
            <div class="form-widget ls-widget">
                <!--CABECERA-->
                <div class="widget-title">
                    <h4><span class="fas fa-building icon"></span> Añadir Propiedad</h4>
                </div>

                <!--CONTENIDO-->
                <div class="widget-content contener">
                    <!--PARTE DEL FORMULARIO PARA AÑADIR UNA IMÁGEN-->
                    <div class="uploading-outer">
                        <div class="uploadButton">
                            <!--INPUT DE LAS FOTOS-->
                            <input class="uploadButton-input" type="file" (change)="fileChangeEvent($event)" id="upload" multiple />

                            <label class="uploadButton-button" for="upload">Subir foto de
                                propiedad</label>
                            <span class="uploadButton-file-name"></span>

                            <!--INPUT DE LAS FOTOS DESTACADAS-->
                            <input class="uploadButton-input" type="file" (change)="fileChangeEvent1($event)" id="upload1" />

                            <label class="uploadButton-button" for="upload1">Subir foto destacada</label>
                            <span class="uploadButton-file-name"></span>
                        </div>

                        <div *ngIf="imagenesguardadas!=0" class="my-2">
                            <h5>IMAGENES AÑADIDAS:</h5>
                            <div *ngFor="let imagenes of imagenesComprobar" class="d-inline">
                                <img src="http://borneoflex.es/borneo/uploads/{{imagenes.imagen}}" alt="" class="previsualizar ml-2" *ngIf="imagenes.destacado==0">
                                <button type="button" class="posicion1" (click)="borrarGuardadas(imagenes.id)"><i
                                        class="far fa-times-circle" *ngIf="imagenes.destacado==0"></i></button>
                            </div>
                        </div>

                        <div *ngIf="imagenesguardadas!=0" class="my-2">
                            <h5>IMAGEN DESTACADA</h5>
                            <div *ngFor="let imagenes of imagenesComprobar" class="d-inline">
                                <img src="http://borneoflex.es/borneo/uploads/{{imagenes.imagen}}" alt="" class="previsualizar ml-2" *ngIf="imagenes.destacado==1">
                                <button type="button" class="posicion1" (click)="borrarGuardadas(imagenes.id)"><i
                                        class="far fa-times-circle" *ngIf="imagenes.destacado==1"></i></button>
                            </div>
                        </div>

                        <div *ngIf="previsualizacion" id="previsualizar" class="my-2">
                            <h5>IMAGENES A AÑADIR:</h5>
                            <div *ngFor="let visualizar of previsualizacion; let i=index" class="d-inline">
                                <img [src]="visualizar" alt="" class="previsualizar ml-2">
                                <button type="button" class="posicion" (click)="borrar(i)"><i
                                        class="far fa-times-circle"></i></button>
                            </div>
                        </div>

                        <div *ngIf="previsualizacionDestaca" id="previsualizar" class="my-2">
                            <h5>IMAGEN DESTACADA A AÑADIR O CAMBIAR:</h5>

                            <img [src]="previsualizacionDestaca" alt="" class="previsualizar ml-2">
                            <button type="button" class="posicion" (click)="borrarDestacada()"><i
                                    class="far fa-times-circle"></i></button>
                        </div>

                    </div>

                    <!-- Formulario -->
                    <div class="default-form">
                        <div class="row">

                            <div class="col-lg-3 col-md-4 col-12">
                                <label>Nombre propiedad</label>
                                <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre propiedad" required>
                                <small *ngIf="nombreNoValido" class="text-danger">Sin espacios ni caracteres especiales,
                                    mínimo 3 letras.</small>
                            </div>

                            <div class="col-lg-2 col-md-4 col-12">
                                <label>Tarifa</label>
                                <input type="text" formControlName="tarifa" class="form-control" placeholder="Tarifa" required>
                                <small *ngIf="tarifaNoValido" class="text-danger">Sin letras ni caracteres
                                    especiales</small>
                            </div>

                            <div class="col-lg-2 col-md-4 col-12">
                                <label>Capacidad</label>
                                <input type="text" formControlName="personas" class="form-control" placeholder="Capacidad" required>
                                <small *ngIf="personasNoValido" class="text-danger">Sin letras ni caracteres
                                    especiales</small>
                            </div>

                            <div class="col-lg-4 col-md-6 col-12">
                                <label>Telefono</label>
                                <input type="text" formControlName="telefono" class="form-control" placeholder="Telefono" required>
                                <small *ngIf="telefonoNoValido" class="text-danger">solo numeros y en total nueve
                                    numeros</small>
                            </div>

                            <!--<div class="col-lg-2">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Tipo de propiedad</label>
                                <select class="custom-select mr-sm-2" formControlName="tipo_propiedad">
                                    <option selected>Escoger...</option>
                                    <option value="residencial">Residencial</option>
                                    <option value="comercial">Comercial</option>
                                    <option value="industrial">Industrial</option>
                                    <option value="apartamentos">Apartamentos</option>
                                </select>
                            </div>-->

                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="access">
                                    <label class="form-check-label">Acceso 24/7</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="mobiliario">
                                    <label class="form-check-label">Mobiliario</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="suministros">
                                    <label class="form-check-label">Suministros</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="networking">
                                    <label class="form-check-label">Networking</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="skype">
                                    <label class="form-check-label">Skype</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="guardabicis">
                                    <label class="form-check-label">Guardabicis</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="cantina">
                                    <label class="form-check-label">Cantina</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="impresora">
                                    <label class="form-check-label">Impresora</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="gimnasio">
                                    <label class="form-check-label">Gimnasio</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="salas_reuniones">
                                    <label class="form-check-label">Reuniones</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="reception">
                                    <label class="form-check-label">Recepción</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="eventos_network">
                                    <label class="form-check-label">Eventos</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="terraza">
                                    <label class="form-check-label">Terraza</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="cafe_relax">
                                    <label class="form-check-label">Zona relax</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="seguridad">
                                    <label class="form-check-label">Seguridad</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="limpieza">
                                    <label class="form-check-label">Limpieza</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="cer_energetica">
                                    <label class="form-check-label">Certificado</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="paqueteria">
                                    <label class="form-check-label">Paqueteria</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="parking">
                                    <label class="form-check-label">Parking</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="wifi">
                                    <label class="form-check-label">Wifi</label>
                                </div>
                                <div class="form-check-inline col-lg-1 col-md-3 col-12">
                                    <input class="form-check-input" type="checkbox" formControlName="coworking">
                                    <label class="form-check-label">Coworking</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="oficina_privada" (change)="datosPrivada()">
                                    <label class="form-check-label">Oficina privada</label>

                                    <div *ngIf="privada==true">
                                        <div class="row">
                                            <!--<div class="col-md-6 col-12">
                                                <label>Rango de personas en puesto privado</label>
                                                <select class="custom-select mr-sm-2"
                                                    formControlName="rango_oficina_privada">
                                                    <option selected>Escoger...</option>
                                                    <option value="1-5">1-5</option>
                                                    <option value="1-10">1-10</option>
                                                    <option value="1-20">1-20</option>
                                                    <option value="1-30">1-30</option>
                                                    <option value="+30">+30</option>
                                                </select>
                                            </div>-->
                                            <div class="col-md-6 col-12">
                                                <label>Precio</label>
                                                <input type="text" formControlName="precio_oficina_privada" class="form-control" placeholder="Precio para oficina privada" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="puesto_fijo" (change)="datosFija()">
                                    <label class="form-check-label">Puesto fijo en espacio abierto</label>

                                    <div *ngIf="fija==true">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <label>Rango de personas en puesto fijo</label>
                                                <select class="custom-select mr-sm-2" formControlName="rango_oficina_fija">
                                                    <option selected>Escoger...</option>
                                                    <option value="1-5">1-5</option>
                                                    <option value="1-10">1-10</option>
                                                    <option value="1-20">1-20</option>
                                                    <option value="1-30">1-30</option>
                                                    <option value="+30">+30</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <label>Precio</label>
                                                <input type="text" formControlName="precio_oficina_fija" class="form-control" placeholder="Precio para puesto fijo en espacio abierto" required>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="puesto_flexible" (change)="datosFlexible()">
                                    <label class="form-check-label">Puesto flexible no fijo</label>

                                    <div *ngIf="flexible==true">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <label>Rango de personas en puesto flexible</label>
                                                <select class="custom-select mr-sm-2" formControlName="rango_puesto_flexible">
                                                    <option selected>Escoger...</option>
                                                    <option value="1-5">1-5</option>
                                                    <option value="1-10">1-10</option>
                                                    <option value="1-20">1-20</option>
                                                    <option value="1-30">1-30</option>
                                                    <option value="+30">+30</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <label>Precio</label>
                                                <input type="text" formControlName="precio_puesto_flexible" class="form-control" placeholder="Precio para puesto flexible no fijo" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="destacado">
                                    <label class="form-check-label">Destacado</label>

                                </div>




                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12">
                                <label>Calle</label>
                                <input type="text" #search formControlName="direccion" class="form-control" placeholder="Dirección" required>
                            </div>

                            <div class="col-lg-2 col-md-6 col-12">
                                <label>Comunidad Autónoma</label>
                                <select class="custom-select mr-sm-2" formControlName="comunidad_autonoma">
                                    <option value='alava'>Álava</option>
                                    <option value='albacete'>Albacete</option>
                                    <option value='alicante'>Alicante/Alacant</option>
                                    <option value='almeria'>Almería</option>
                                    <option value='asturias'>Asturias</option>
                                    <option value='avila'>Ávila</option>
                                    <option value='badajoz'>Badajoz</option>
                                    <option value='barcelona'>Barcelona</option>
                                    <option value='burgos'>Burgos</option>
                                    <option value='caceres'>Cáceres</option>
                                    <option value='cadiz'>Cádiz</option>
                                    <option value='cantabria'>Cantabria</option>
                                    <option value='castellon'>Castellón/Castelló</option>
                                    <option value='ceuta'>Ceuta</option>
                                    <option value='ciudadreal'>Ciudad Real</option>
                                    <option value='cordoba'>Córdoba</option>
                                    <option value='cuenca'>Cuenca</option>
                                    <option value='girona'>Girona</option>
                                    <option value='laspalmas'>Las Palmas</option>
                                    <option value='granada'>Granada</option>
                                    <option value='guadalajara'>Guadalajara</option>
                                    <option value='guipuzcoa'>Guipúzcoa</option>
                                    <option value='huelva'>Huelva</option>
                                    <option value='huesca'>Huesca</option>
                                    <option value='illesbalears'>Illes Balears</option>
                                    <option value='jaen'>Jaén</option>
                                    <option value='acoruña'>A Coruña</option>
                                    <option value='larioja'>La Rioja</option>
                                    <option value='leon'>León</option>
                                    <option value='lleida'>Lleida</option>
                                    <option value='lugo'>Lugo</option>
                                    <option value='madrid'>Madrid</option>
                                    <option value='malaga'>Málaga</option>
                                    <option value='melilla'>Melilla</option>
                                    <option value='murcia'>Murcia</option>
                                    <option value='navarra'>Navarra</option>
                                    <option value='ourense'>Ourense</option>
                                    <option value='palencia'>Palencia</option>
                                    <option value='pontevedra'>Pontevedra</option>
                                    <option value='salamanca'>Salamanca</option>
                                    <option value='segovia'>Segovia</option>
                                    <option value='sevilla'>Sevilla</option>
                                    <option value='soria'>Soria</option>
                                    <option value='tarragona'>Tarragona</option>
                                    <option value='santacruztenerife'>Santa Cruz de Tenerife</option>
                                    <option value='teruel'>Teruel</option>
                                    <option value='toledo'>Toledo</option>
                                    <option value='valencia'>Valencia/Valéncia</option>
                                    <option value='valladolid'>Valladolid</option>
                                    <option value='vizcaya'>Vizcaya</option>
                                    <option value='zamora'>Zamora</option>
                                    <option value='zaragoza'>Zaragoza</option>
                                </select>
                            </div>

                            <div class="col-lg-2 col-md-4 col-12">
                                <label>Ciudad</label>
                                <select class="custom-select mr-sm-2" formControlName="ciudad" (change)="cambiarBarrios()" id="ciudad">
                                    <option value='Barcelona'>Barcelona</option>
                                    <option value='Madrid'>Madrid</option>
                                    <option value='Oviedo'>Oviedo</option>
                                    <option value='Valencia'>Valencia</option>
                                    <option value='Malaga'>Malaga</option>
                                    <option value='Sevilla'>Sevilla</option>
                                    <option value='Bilbao'>Bilbao</option>
                                </select>
                            </div>

                            <div class="col-lg-4 col-md-4 col-12">
                                <label>Barrio</label>
                                <select class="custom-select mr-sm-2" formControlName="barrio">
                                    <option value='{{barrio}}' *ngFor="let barrio of barrios">{{barrio}}</option>
                                </select>

                                <!--<input type="text" formControlName="barrio" class="form-control"
                                    placeholder="barrio de la ciudad" required>
                                <small *ngIf="barrioNoValido" class="text-danger">No poner carácteres especiales</small>-->
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <label for="exampleFormControlTextarea1">Descripción</label>
                                <textarea class="form-control" formControlName="descripcion" required></textarea>
                            </div>
                        </div>

                        <div class="row mb-10">
                            <div class="col-lg-6 col-12">
                                <button class="theme-btn btn-style-two" type="submit" [disabled]="!propertyForm.valid" name="submit-form" id="submit">Enviar</button>
                                <small *ngIf="!propertyForm.valid" class="text-danger font-weight-bold">Por favor
                                    rellene correctamente el formulario*</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>