<div class="page-wrapper bg_alice">
    <app-menu></app-menu>

    <section class="page-title" style="background-image: url(assets/images/nosotros/our.png);">
        <div class="auto-container">
            <h1>AVISO LEGAL</h1>
        </div>
    </section>

    <section class="what-we-do pb-2">
        <div class="auto-container">
            <div class="sec-title">
                <h4 class="my-2">Aviso legal</h4>
                <div class="text">El presente aviso legal (en adelante, el «Aviso Legal») regula el uso del servicio del portal de Internet http://borneoflex.es/ (en adelante, el «Web») de BORNEO REAL ESTATE, S.L. con domicilio social en Edificio Cuzco II C/ Poeta Joan
                    Maragall, 38 - 2º izquierda, 28020, Madrid con CIF B88527684.
                </div>
                <h4 class="my-2">Legislación</h4>
                <div class="text">Con carácter general las relaciones entre BORNEO REAL ESTATE, S.L. con los Usuarios de sus servicios telemáticos, presentes en la web, se encuentran sometidas a la legislación y jurisdicción españolas. Las partes renuncian expresamente
                    al fuero que les pudiera corresponder y someten expresamente a los Juzgados y Tribunales de MADRID para resolver cualquier controversia que pueda surgir en la interpretación o ejecución de las presentes condiciones contractuales.
                </div>
                <h4 class="my-2">Contenido y uso</h4>
                <div class="text">El Usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con BORNEO REAL ESTATE, S.L.. El titular del web no se identifica con las opiniones vertidas en el mismo
                    por sus colaboradores. La Empresa se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su Web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través
                    de la misma como la forma en la que éstos aparezcan presentados o localizados en sus servidores.
                </div>
                <h4 class="my-2">Propiedad intelectual e industrial</h4>
                <div class="text">Los derechos de propiedad intelectual del contenido de las páginas web, su diseño gráfico y códigos son titularidad de BORNEO REAL ESTATE, S.L. y, por tanto, queda prohibida su reproducción, distribución, comunicación pública, transformación
                    o cualquier otra actividad que se pueda realizar con los contenidos de sus páginas web ni aun citando las fuentes, salvo consentimiento por escrito de BORNEO REAL ESTATE, S.L.. Todos los nombres comerciales, marcas o signos distintos
                    de cualquier clase contenidos en las páginas web de la Empresa son propiedad de sus dueños y están protegidos por ley.
                </div>
                <h4 class="my-2">Enlaces (Links)</h4>
                <div class="text">La presencia de enlaces (links) en las páginas web de BORNEO REAL ESTATE, S.L. tiene finalidad meramente informativa y en ningún caso supone sugerencia, invitación o recomendación sobre los mismos.
                </div>
            </div>
        </div>
    </section>
</div>

<app-footer></app-footer>