<app-menu class="no-fix"></app-menu>
<div class="page-wrapper">
    <section class="ls-section">
        <div>
            <div class="container-fluid" *ngIf="cambiodepagina==false">
                <div class="row">
                    <!--<div class="col-6">-->
                    <div [ngClass]="{'col-6': !isMobile(), 'col-12': isMobile()}">
                        <div>
                            <ul class="top-filters">
                                <!--<li class="active"><a href="#">Filtros</a></li>-->
                                <li><a (click)="cerrarMapa()">Cerrar mapa</a></li>
                            </ul>

                            <div class="listing-search-form" style="margin-bottom: 0px !important;">
                                <form method="post" action="index.html">
                                    <div class="row">
                                        <div class="form-group col-lg-4 col-md-12 col-sm-12 location" *ngIf="ciudad=='Madrid'">
                                            <select id="op" [formControl]="selected">
                                                <option value="null" id="opts">Zona</option>
                                                <option value="Salamanca" id="opts">Salamanca</option>
                                                <option value="Retiro" id="opts">Retiro</option>
                                                <option value="Chamberi" id="opts">Chamberí</option>
                                                <option value="Moncloa" id="opts">Moncloa</option>
                                                <option value="Chamartin" id="opts">Chamartín</option>
                                                <option value="Cuzco-Cuatro Torres" id="opts">Cuzco-Cuatro Torres</option>
                                                <option value="Azca" id="opts">Azca</option>
                                                <option value="Centro" id="opts">Centro</option>
                                                <option value="Atocha" id="opts">Atocha</option>
                                                <option value="A1" id="opts">A1</option>
                                                <option value="A2" id="opts">A2</option>
                                                <option value="A6" id="opts">A6</option>
                                                <option value="Periferia" id="opts">Periferia</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-lg-4 col-md-12 col-sm-12 location" *ngIf="ciudad=='Barcelona'">
                                            <select id="op" [formControl]="selected">
                                                <option value="null" id="opts">Todos</option>
                                                <option value="Centro Ciudad" id="opts">Centro ciudad</option>
                                                <option value="Alta Diagonal" id="opts">Alta diagonal</option>
                                                <option value="Paseo de Gracia" id="opts">Paseo de Gracia</option>
                                                <option value="Ciutat Vella" id="opts">Ciutat Vella</option>
                                                <option value="22@" id="opts">22@</option>
                                                <option value="Extrarradio" id="opts">Extrarradio</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-lg-4 col-md-12 col-sm-12 location" *ngIf="ciudad!='Barcelona' && ciudad!='Madrid'">
                                            <select id="op" [formControl]="selected">
                                                <option value="null" id="opts">Todos</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-lg-4 col-md-12 col-sm-12 location">
                                            <select name="checkbox" [(ngModel)]='checkboxFilter'>
                                                <option value="todos" id="opts">Tipo de espacio</option>
                                                <option value="oficinas-privadas" id="opts">Oficinas Privadas</option>
                                                <option value="puestos-fijos-espacios-abiertos" id="opts">Puestos permanentes en espacios abiertos</option>
                                                <option value="puestos-flexibles" id="opts">Puestos flexibles no fijos</option>
                                                <option value="reuniones" id="opts">Salas de reuniones</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-lg-2 col-md-12 col-sm-12 location">
                                            <button (click)="search()" class="estilos">BUSCAR</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="checkbox-outer">
                                <!--<h4>Etiquetas</h4>-->
                                <ul class="checkboxes inline">
                                    <!-- <li>
                                        <input id="check-a" type="checkbox" name="check" (change)="search()">
                                        <label for="check-a">Oficinas privadas</label>
                                    </li>
                                    <li>
                                        <input id="check-b" type="checkbox" name="check" (change)="search()">
                                        <label for="check-b">Puestos fijos en espacios abiertos</label>
                                    </li>
                                    <li>
                                        <input id="check-c" type="checkbox" name="check" (change)="search()">
                                        <label for="check-c">Puestos flexibles no fijos</label>
                                    </li>
                                    <li>
                                        <input id="check-d" type="checkbox" name="check" (change)="search()">
                                        <label for="check-d">Salas de reuniones</label>
                                    </li>-->
                                    <!--<li>
                                        <input id="check-a" type="checkbox" name="check" (change)="search()">
                                        <label for="check-a">Acceso 24/7</label>
                                    </li>
                                    <li>
                                        <input id="check-b" type="checkbox" name="check" (change)="search()">
                                        <label for="check-b">Salas de reuniones</label>
                                    </li>
                                    <li>
                                        <input id="check-c" type="checkbox" name="check" (change)="search()">
                                        <label for="check-c">Recepción</label>
                                    </li>
                                    <li>
                                        <input id="check-d" type="checkbox" name="check" (change)="search()">
                                        <label for="check-d">Eventos</label>
                                    </li>
                                    <li>
                                        <input id="check-e" type="checkbox" name="check" (change)="search()">
                                        <label for="check-e">Terraza</label>
                                    </li>
                                    <li>
                                        <input id="check-f" type="checkbox" name="check" (change)="search()">
                                        <label for="check-f">Zona de relax</label>
                                    </li>
                                    <li>
                                        <input id="check-g" type="checkbox" name="check" (change)="search()">
                                        <label for="check-g">Seguridad</label>
                                    </li>
                                    <li>
                                        <input id="check-h" type="checkbox" name="check" (change)="search()">
                                        <label for="check-h">Limpieza</label>
                                    </li>
                                    <li>
                                        <input id="check-i" type="checkbox" name="check" (change)="search()">
                                        <label for="check-i">Certificado</label>
                                    </li>
                                    <li>
                                        <input id="check-j" type="checkbox" name="check" (change)="search()">
                                        <label for="check-j">Paqueteria</label>
                                    </li>
                                    <li>
                                        <input id="check-k" type="checkbox" name="check" (change)="search()">
                                        <label for="check-k">Parking</label>
                                    </li>
                                    <li>
                                        <input id="check-l" type="checkbox" name="check" (change)="search()">
                                        <label for="check-l">Wifi</label>
                                    </li>
                                    <li>
                                        <input id="check-m" type="checkbox" name="check" (change)="search()">
                                        <label for="check-m">Coworking</label>
                                    </li>-->
                                </ul>
                            </div>
                        </div>

                        <div class="ls-outer">
                            <div class="ls-switcher">
                                <div class="showing-result">
                                    <div class="arrange">
                                        <a href="#" class="active"><span class="icon flaticon-squares"></span></a>
                                    </div>
                                    <div class="text">{{contador}} Resultados encontrados</div>
                                </div>
                            </div>

                            <nav>
                                <ul>
                                    <li>
                                        <pagination-controls (pageChange)="page = $event"></pagination-controls>
                                    </li>
                                </ul>
                            </nav>

                            <div class="row">
                                <div class="listing-block col-lg-6 col-md-12" *ngFor="let propiedad of propiedadesFiltradas | paginate: { itemsPerPage: 2, currentPage: page }">
                                    <div class="inner-box">
                                        <div class="image-box" (click)="prueba(propiedad)">
                                            <figure class="image"><img [src]="propiedad.imgUrl" alt="" class="imgTamano">
                                            </figure>
                                            <div class="tags">
                                                <!--<span>+{{propiedad.personas}} personas</span>
                                                <span>Desde: {{propiedad.tarifa}} euros</span>-->
                                            </div>
                                        </div>
                                        <div class="lower-content tamano">
                                            <!-- <div class="user-thumb"><img src="images/resource/user-thumb-1.jpg" alt="" /></div> -->
                                            <!--<div class="rating">
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                            </div>-->
                                            <h3><a [routerLink]="['/view/office',propiedad.id]" target="_blank">{{propiedad.nombre}}<span
                                                        class="icon icon-verified"></span></a></h3>

                                            <!--<div class="text">{{propiedad.descripcion | slice:0:200}}...</div>-->
                                            <div class="text">Puestos disponibles: <b>+{{propiedad.personas}}</b></div>
                                            <div class="text">Desde: <b>{{propiedad.tarifa}} euros</b></div>
                                            <div class="text">Iconos servicios:
                                                <ul class="listing-features1">
                                                    <li *ngIf="propiedad.access=='true'" data-toggle="tooltip" data-placement="top" title="Acceso 24/7"><i class="fas fa-stopwatch icon"></i></li>
                                                    <li *ngIf="propiedad.mobiliario=='true'" data-toggle="tooltip" data-placement="top" title="Mobiliario"><i class="fas fa-building icon"></i></li>
                                                    <li *ngIf="propiedad.suministros=='true'" data-toggle="tooltip" data-placement="top" title="Suministros"><i class="fas fa-box icon"></i></li>
                                                    <li *ngIf="propiedad.networking=='true'" data-toggle="tooltip" data-placement="top" title="Networking"><i class="fas fa-ethernet icon"></i></li>
                                                    <li *ngIf="propiedad.skype=='true'" data-toggle="tooltip" data-placement="top" title="Skype"><i class="fab fa-skype icon"></i></li>
                                                    <li *ngIf="propiedad.guardabicis=='true'" data-toggle="tooltip" data-placement="top" title="Guardabicis"><i class="fas fa-bicycle icon"></i></li>
                                                    <li *ngIf="propiedad.cantina=='true'" data-toggle="tooltip" data-placement="top" title="Cantina"><i class="fas fa-glass-martini icon"></i></li>
                                                    <li *ngIf="propiedad.impresora=='true'" data-toggle="tooltip" data-placement="top" title="impresora"><i class="fas fa-print icon"></i></li>
                                                    <li *ngIf="propiedad.gimnasio=='true'" data-toggle="tooltip" data-placement="top" title="Gimnasio"><i class="fas fa-dumbbell icon"></i></li>
                                                    <li *ngIf="propiedad.salas_reuniones=='true'" data-toggle="tooltip" data-placement="top" title="Salas de reuniones"><i class="fas fa-handshake icon"></i></li>
                                                    <li *ngIf="propiedad.reception=='true'" data-toggle="tooltip" data-placement="top" title="Recepción"><i class="fas fa-concierge-bell icon"></i></li>
                                                    <li *ngIf="propiedad.eventos_network=='true'" data-toggle="tooltip" data-placement="top" title="Eventos-network"><i class="fas fa-calendar icon"></i></li>
                                                    <li *ngIf="propiedad.terraza=='true'" data-toggle="tooltip" data-placement="top" title="Terraza"><i class="fas fa-cocktail icon"></i></li>
                                                    <li *ngIf="propiedad.cafe_relax=='true'" data-toggle="tooltip" data-placement="top" title="Zona café/relax"><i class="fas fa-coffee icon"></i></li>
                                                    <li *ngIf="propiedad.seguridad=='true'" data-toggle="tooltip" data-placement="top" title="Seguridad 24 horas"><i class="fas fa-shield-alt icon"></i></li>
                                                    <li *ngIf="propiedad.limpieza=='true'" data-toggle="tooltip" data-placement="top" title="Limpieza"><i class="fas fa-soap icon"></i></li>
                                                    <li *ngIf="propiedad.cer_energetica=='true'" data-toggle="tooltip" data-placement="top" title="Certificado energético"><i class="fas fa-stamp icon"></i></li>
                                                    <li *ngIf="propiedad.paqueteria=='true'" data-toggle="tooltip" data-placement="top" title="Paquetería"><i class="fas fa-box icon"></i></li>
                                                    <li *ngIf="propiedad.parking=='true'" data-toggle="tooltip" data-placement="top" title="Parking"><i class="fas fa-parking icon"></i></li>
                                                    <li *ngIf="propiedad.wifi=='true'" data-toggle="tooltip" data-placement="top" title="Wifi"><i class="fas fa-wifi icon"></i></li>
                                                    <li *ngIf="propiedad.coworking=='true'" data-toggle="tooltip" data-placement="top" title="Coworking"><i class="far fa-building icon"></i></li>
                                                </ul>
                                            </div>
                                            <ul class="info listaColocacion">
                                                <li><span class="flaticon-pin"></span> <b>{{propiedad.ciudad}}</b></li>
                                                <li><span class="flaticon-phone-call"></span>
                                                    <b>{{propiedad.telefono}}</b>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <nav>
                                <ul>
                                    <li>
                                        <pagination-controls (pageChange)="page = $event"></pagination-controls>
                                    </li>
                                </ul>
                            </nav> -->
                        </div>
                    </div>

                    <!-------------------------------------GOOGLE MAPS------------------------------------------------------------>
                    <!--<div class="col-6">-->
                    <div [ngClass]="{'col-6': !isMobile(), 'col-12 -map-mobile': isMobile()}">
                        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeId]="mapTypeId" [styles]='styles' (mapReady)="mapReadyHandler($event)">
                            <agm-marker *ngFor="let marker of markers" [latitude]="marker.position.lat" [longitude]="marker.position.lng" [label]="marker.label" [zoom]="zoom">
                                <agm-info-window><span style="cursor: pointer;" (click)='redirectToPage(marker.idProp)'>{{marker.nameProp}}</span></agm-info-window>
                            </agm-marker>
                        </agm-map>
                    </div>
                </div>
            </div>

            <!-----------------------------------------------------------------PAGINA SIN MAPA------------------------------------------------->
            <div class="container-fluid" *ngIf="cambiodepagina==true">
                <div class="row">
                    <div class="col-12">
                        <div class="upper-box">
                            <ul class="top-filters">
                                <!--<li class="active"><a href="#">Filtros</a></li>-->
                                <li><a (click)="cerrarMapa()">Abrir mapa</a></li>
                            </ul>

                            <div class="listing-search-form">
                                <form method="post" action="index.html">
                                    <div class="row">
                                        <div class="form-group col-lg-2 col-md-4 col-sm-12 location" *ngIf="ciudad=='Madrid'">
                                            <select id="op" [formControl]="selected">
                                                <option value="null" id="opts">Zona</option>
                                                <option value="Salamanca" id="opts">Salamanca</option>
                                                <option value="Retiro" id="opts">Retiro</option>
                                                <option value="Chamberi" id="opts">Chamberí</option>
                                                <option value="Moncloa" id="opts">Moncloa</option>
                                                <option value="Chamartin" id="opts">Chamartín</option>
                                                <option value="Cuzco-Cuatro Torres" id="opts">Cuzco-Cuatro Torres</option>
                                                <option value="Azca" id="opts">Azca</option>
                                                <option value="Centro" id="opts">Centro</option>
                                                <option value="Atocha" id="opts">Atocha</option>
                                                <option value="A1" id="opts">A1</option>
                                                <option value="A2" id="opts">A2</option>
                                                <option value="A6" id="opts">A6</option>
                                                <option value="Periferia" id="opts">Periferia</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-lg-2 col-md-4 col-sm-12 location" *ngIf="ciudad=='Barcelona'">
                                            <select id="op" [formControl]="selected">
                                                <option value="null" id="opts">Zona</option>
                                                <option value="Centro Ciudad" id="opts">Centro ciudad</option>
                                                <option value="Alta Diagonal" id="opts">Alta diagonal</option>
                                                <option value="Paseo de Gracia" id="opts">Paseo de Gracia</option>
                                                <option value="Ciutat Vella" id="opts">Ciutat Vella</option>
                                                <option value="22@" id="opts">22@</option>
                                                <option value="Extrarradio" id="opts">Extrarradio</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-lg-2 col-md-4 col-sm-12 location" *ngIf="ciudad!='Barcelona' && ciudad!='Madrid'">
                                            <select id="op" [formControl]="selected">
                                                <option value="null" id="opts">Todos</option>
                                            </select>
                                        </div>

                                        <div class="form-group col-lg-2 col-md-4 col-sm-12 location">
                                            <button (click)="search()" class="estilos">BUSCAR</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="checkbox-outer">
                                <!--<h4>Etiquetas</h4>-->
                                <ul class="checkboxes inline">
                                    <li>
                                        <input id="check-a" type="checkbox" name="check" (change)="search()">
                                        <label for="check-a">Oficinas privadas</label>
                                    </li>
                                    <li>
                                        <input id="check-b" type="checkbox" name="check" (change)="search()">
                                        <label for="check-b">Puestos permanentes en espacios abiertos</label>
                                    </li>
                                    <li>
                                        <input id="check-c" type="checkbox" name="check" (change)="search()">
                                        <label for="check-c">Puestos flexibles no fijos</label>
                                    </li>
                                    <li>
                                        <input id="check-d" type="checkbox" name="check" (change)="search()">
                                        <label for="check-d">Salas de reuniones</label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="ls-outer">
                            <div class="ls-switcher">
                                <div class="showing-result">
                                    <div class="arrange">
                                        <a href="#" class="active"><span class="icon flaticon-squares"></span></a>
                                    </div>
                                    <div class="text">{{contador}} Resultados encontrados</div>
                                </div>
                            </div>

                            <nav>
                                <ul>
                                    <li>
                                        <pagination-controls (pageChange)="pageOpen = $event"></pagination-controls>
                                    </li>
                                </ul>
                            </nav>

                            <div class="row">
                                <div class="listing-block col-lg-3 col-md-6" *ngFor="let propiedad of propiedadesFiltradas | paginate: { itemsPerPage: 8, currentPage: pageOpen }">
                                    <div class="inner-box">
                                        <div class="image-box">
                                            <figure class="image"><img [src]="propiedad.imgUrl" alt="" class="imgTamano">
                                            </figure>
                                            <div class="tags">
                                                <!--<span>+{{propiedad.personas}} personas</span>
                                                <span>Desde: {{propiedad.tarifa}} euros</span>-->
                                            </div>
                                        </div>
                                        <div class="lower-content tamano">
                                            <!-- <div class="user-thumb"><img src="images/resource/user-thumb-1.jpg" alt="" /></div> -->
                                            <!--<div class="rating">
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                            </div>-->
                                            <h3><a [routerLink]="['/view/office',propiedad.id]">{{propiedad.nombre}}<span
                                                        class="icon icon-verified"></span></a></h3>

                                            <!--<div class="text">{{propiedad.descripcion | slice:0:200}}...</div>-->
                                            <div class="text">Puestos disponibles: <b>+{{propiedad.personas}}</b></div>
                                            <div class="text">Desde: <b>{{propiedad.tarifa}} euros</b></div>
                                            <div class="text">Iconos servicios:
                                                <ul class="listing-features1">
                                                    <li *ngIf="propiedad.access=='true'"><i class="fas fa-stopwatch icon"></i></li>
                                                    <li *ngIf="propiedad.mobiliario=='true'"><i class="fas fa-building icon"></i></li>
                                                    <li *ngIf="propiedad.suministros=='true'"><i class="fas fa-box icon"></i></li>
                                                    <li *ngIf="propiedad.networking=='true'"><i class="fas fa-ethernet icon"></i></li>
                                                    <li *ngIf="propiedad.skype=='true'"><i class="fab fa-skype icon"></i></li>
                                                    <li *ngIf="propiedad.guardabicis=='true'"><i class="fas fa-bicycle icon"></i></li>
                                                    <li *ngIf="propiedad.cantina=='true'"><i class="fas fa-glass-martini icon"></i></li>
                                                    <li *ngIf="propiedad.impresora=='true'"><i class="fas fa-print icon"></i></li>
                                                    <li *ngIf="propiedad.gimnasio=='true'"><i class="fas fa-dumbbell icon"></i></li>
                                                    <li *ngIf="propiedad.salas_reuniones=='true'"><i class="fas fa-handshake icon"></i></li>
                                                    <li *ngIf="propiedad.reception=='true'"><i class="fas fa-concierge-bell icon"></i></li>
                                                    <li *ngIf="propiedad.eventos_network=='true'"><i class="fas fa-calendar icon"></i></li>
                                                    <li *ngIf="propiedad.terraza=='true'"><i class="fas fa-cocktail icon"></i></li>
                                                    <li *ngIf="propiedad.cafe_relax=='true'"><i class="fas fa-coffee icon"></i></li>
                                                    <li *ngIf="propiedad.seguridad=='true'"><i class="fas fa-shield-alt icon"></i></li>
                                                    <li *ngIf="propiedad.limpieza=='true'"><i class="fas fa-soap icon"></i></li>
                                                    <li *ngIf="propiedad.cer_energetica=='true'"><i class="fas fa-stamp icon"></i></li>
                                                    <li *ngIf="propiedad.paqueteria=='true'"><i class="fas fa-box icon"></i></li>
                                                    <li *ngIf="propiedad.parking=='true'"><i class="fas fa-parking icon"></i></li>
                                                    <li *ngIf="propiedad.wifi=='true'"><i class="fas fa-wifi icon"></i>
                                                    </li>
                                                    <li *ngIf="propiedad.coworking=='true'"><i class="far fa-building icon"></i></li>
                                                </ul>
                                            </div>
                                            <ul class="info listaColocacion">
                                                <li><span class="flaticon-pin"></span> <b>{{propiedad.ciudad}}</b></li>
                                                <li><span class="flaticon-phone-call"></span>
                                                    <b>{{propiedad.telefono}}</b>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <nav>
                                <ul>
                                    <li>
                                        <pagination-controls (pageChange)="page = $event"></pagination-controls>
                                    </li>
                                </ul>
                            </nav> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>